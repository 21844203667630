import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IProfileSettings } from '../../../../../types/profileSetting.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { profileSettings } from '../../../../../schemas/account/profileSetting.schema';
import clsx from 'clsx';
import { IProfileInfo } from '../../../../../types/accountSettings.types';
import { updateProfileInfo } from '../../../../../services/designer';
import { convertImageToBase64 } from '../../../../../utils/image';
import avatarPlaceholder from '../../../../../assets/images/layout/logo_lounge_skull.svg';
import ProfileImages from '../../../ProfileImages';
import SkeletonForm from './SkeletonForm';
import useAuth from '../../../../../hooks/useAuth';
import useSpinner from '../../../../../hooks/useSpinner';
import useToast from '../../../../../hooks/useToast';

const ProfileSettings = ({
  profileInfo,
  isLoading,
}: {
  profileInfo: IProfileInfo | undefined;
  isLoading: boolean;
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const { setAvatar } = useAuth();
  const spinner = useSpinner();
  const toast = useToast();

  useEffect(() => {
    if (profileInfo) {
      setValue('avatar_filename', profileInfo.avatar_filename);
      setFileUrl(profileInfo.avatar_url);

      setValue('first_name', profileInfo.first_name);
      setValue('last_name', profileInfo.last_name);
      setValue('company', profileInfo.company);
      setValue('company_description', profileInfo.company_description);
      setValue('url', profileInfo.url);
      setValue('facebook', profileInfo.facebook);
      setValue('twitter', profileInfo.twitter);
      setValue('instagram', profileInfo.instagram);
      setValue('dribbble', profileInfo.dribbble);
      setValue('behance', profileInfo.behance);
    }
  }, [profileInfo]);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<IProfileSettings>({
    resolver: yupResolver(profileSettings),
    defaultValues: {
      avatar_filename: profileInfo?.avatar_filename,
      avatar_base64: null,
      first_name: profileInfo?.first_name,
      last_name: profileInfo?.last_name,
      company: profileInfo?.company,
      company_description: profileInfo?.company_description,
      url: profileInfo?.url,
      facebook: profileInfo?.facebook,
      twitter: profileInfo?.twitter,
      instagram: profileInfo?.instagram,
      dribbble: profileInfo?.dribbble,
      behance: profileInfo?.behance,
    },
  });

  const handleFileSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = async e => {
        if (e.target?.result) {
          const fileDataURL = e.target.result as string;
          setFileUrl(fileDataURL);
          setValue('avatar_filename', files[0].name);
          setValue('avatar_base64', await convertImageToBase64(fileDataURL));
        }
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleFileDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    handleFileSelect(event.dataTransfer.files);
  }, []);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  }, []);

  const onSubmit = (profileData: IProfileSettings) => {
    spinner.open();

    updateProfileInfo(profileData).then(response => {
      if (response) {
        if (response.avatar_url) {
          setAvatar(`${response.avatar_url}?t=${new Date().getTime()}`);
        }
        toast.addToast({
          type: 'success',
          message: 'Profile updated!',
        });
      } else {
        toast.addToast({
          type: 'error',
          message: 'Error updating profile.',
        });
      }
      spinner.close();
    });
  };

  return (
    <section>
      <div className="content_outer">
        {isLoading ? (
          <SkeletonForm />
        ) : (
          <div id="edit-profile">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="profile_form"
              encType="multipart/form-data"
            >
              <div className="profile_image_outer">
                <div className="upload-item">
                  <figure className="upload-preview">
                    <div
                      id="drop-bucket"
                      className={clsx('upload-bucket', isDragOver && 'is-drag-over')}
                      onDrop={handleFileDrop}
                      onDragOver={handleDragOver}
                      onDragLeave={() => setIsDragOver(false)}
                    >
                      {fileUrl ? (
                        <img src={fileUrl} alt="" />
                      ) : (
                        <img src={avatarPlaceholder} alt="Avatar Placeholder" />
                      )}
                    </div>
                  </figure>
                </div>
                <label htmlFor="upload-file-input">
                  <strong>Choose Profile Image</strong>
                  <span> or drag and drop</span>
                </label>
                <input
                  id="upload-file-input"
                  type="file"
                  name="file"
                  onChange={e => handleFileSelect(e.target.files)}
                />
              </div>

              <div className="form-group-wrap--name">
                <div className="form-group">
                  <label className="control-label">First Name</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('first_name')}
                  />
                </div>

                <div className="form-group">
                  <label className="control-label">Last Name</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('last_name')}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label">Company</label>
                <input className="form-control" type="text" {...register('company')} />
              </div>

              <div className="form-group">
                <label className="control-label">Bio</label>
                <textarea
                  className="form-control"
                  {...register('company_description')}
                ></textarea>
              </div>

              <div className="form-group">
                <label className="control-label">Website</label>
                <input
                  className="form-control"
                  type="text"
                  {...register('url')}
                  placeholder="https://www.example.com"
                />
                {errors.url?.message && (
                  <span className="error_message">{errors.url?.message}</span>
                )}
              </div>

              <div className="form-group">
                <label className="control-label">Facebook</label>
                <input
                  className="form-control"
                  type="text"
                  {...register('facebook')}
                  placeholder="https://www.example.com"
                />
                {errors.facebook?.message && (
                  <span className="error_message">{errors.facebook?.message}</span>
                )}
              </div>

              <div className="form-group">
                <label className="control-label">X</label>
                <input
                  className="form-control"
                  type="text"
                  {...register('twitter')}
                  placeholder="https://www.example.com"
                />
                {errors.twitter?.message && (
                  <span className="error_message">{errors.twitter?.message}</span>
                )}
              </div>

              <div className="form-group">
                <label className="control-label">Instagram</label>
                <input
                  className="form-control"
                  type="text"
                  {...register('instagram')}
                  placeholder="https://www.example.com"
                />
                {errors.instagram?.message && (
                  <span className="error_message">{errors.instagram?.message}</span>
                )}
              </div>

              <div className="form-group">
                <label className="control-label">Dribbble</label>
                <input
                  className="form-control"
                  type="text"
                  {...register('dribbble')}
                  placeholder="https://www.example.com"
                />
                {errors.dribbble?.message && (
                  <span className="error_message">{errors.dribbble?.message}</span>
                )}
              </div>

              <div className="form-group">
                <label className="control-label">Behance</label>
                <input
                  className="form-control"
                  type="text"
                  {...register('behance')}
                  placeholder="https://www.example.com"
                />
                {errors.behance?.message && (
                  <span className="error_message">{errors.behance?.message}</span>
                )}
              </div>

              <div className="button_outer">
                <button className="btn btn-primary" type="submit">
                  Save Profile
                </button>
              </div>
            </form>

            <ProfileImages />
          </div>
        )}
      </div>
    </section>
  );
};

export default ProfileSettings;
