import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const ProtectedRoute = () => {
  const { isLoading, isAuthenticated, isActive } = useAuth();

  if (isLoading) return null;
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (!isActive) return <Navigate to="/checkout" />;
  return <Outlet />;
};

export default ProtectedRoute;
