import React from 'react';

const AllBooks = () => {
  return (
    <div className="books_group_outer" id="books">
      <div className="books_group">
        <article className="new">
          <figure>
            <a href="?" target="_blank">
              <img src="https://www.logolounge.com/images/ll-books/llb_13.jpg" alt="" />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a href="?" target="_blank">
                LogoLounge Book 13
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner &amp; Sarah Whitman</li>
            </ul>
            <div className="button_outer">
              <a className="button" href="?" target="_blank" title="">
                Buy Now
              </a>
            </div>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="https://store.bookbaby.com/book/Logolounge-12"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_12.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="https://store.bookbaby.com/book/Logolounge-12"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;12
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner &amp; Emily Potts</li>
              <li>Published: Dec 2020</li>
            </ul>
            <p>
              This 12th edition of the bestselling LogoLounge book series features the
              leading edge of identity design created by highly accomplished and
              noteworthy up-and-coming designers from around the world. Carefully curated
              by an expert panel made up of some of the most revered names in the
              industry, this volume offers up endless opportunity for insight and delight.
              Discover 3,000 amazingly crafted logos organized into unique visual
              categories for easy reference. Throughout the book, bonus case studies from
              respected firms including Mattson Creative, Hey Studio, Refinery 43, Uniko
              Studio and Mackey Saturday provide a deeper look at design genius at work.
              This is the definitive identity resource for designers, creative directors,
              brand managers, and more. Logolounge.com is the most comprehensive and
              searchable logo database available today. Members enjoy a wide range of
              benefits, including unlimited uploads that become entries for consideration
              in the LogoLounge book series.{' '}
              <a href="/join" target="_blank">
                Join our community
              </a>{' '}
              and make your mark on logo history.
            </p>

            <a
              className="button"
              href="https://store.bookbaby.com/book/Logolounge-12"
              target="_blank"
              title="Buy LogoLounge 12 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="https://store.bookbaby.com/book/Logolounge-11"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_11.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="https://store.bookbaby.com/book/Logolounge-11"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;11
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner &amp; Sarah Whitman</li>
              <li>Published: May 2019</li>
            </ul>
            <p>
              This 11th edition of the bestselling LogoLounge book series features the
              leading edge of identity design created by highly accomplished and
              noteworthy up-and-coming designers from around the world. Carefully curated
              by an expert panel made up of some of the most revered names in the
              industry, this volume offers up endless opportunity for insight and delight.
              Discover 2,500 amazingly crafted logos organized into unique visual
              categories for easy reference. Throughout the book, bonus case studies from
              respected firms including Stefan Sagmeister, Pentagram London, Hoodzpah,
              Focus Lab and Tad Carpenter provide a deeper look at design genius at work.
              This is the definitive identity resource for designers, creative directors,
              brand managers, and more.
            </p>

            <a
              className="button"
              href="https://store.bookbaby.com/book/Logolounge-11"
              target="_blank"
              title="Buy LogoLounge 11 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>
        <article>
          <figure>
            <a
              href="https://store.bookbaby.com/book/Logolounge-10"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_10.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="https://store.bookbaby.com/book/Logolounge-10"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;10
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner &amp; Emily Potts</li>
              <li>Published: October 2017</li>
            </ul>
            <p>
              This landmark edition ups the ante with 2,500 logos picked by an eminent
              panel of 10 judges from around the world. It also gives you the rare
              opportunity to learn stories behind inspired logos from well-known designers
              and up-and-coming talents, including Alex Rinker, Odney, Steely Works, Simon
              Frouws, and more. And, it’s available in digital!
            </p>

            <a
              className="button"
              href="https://store.bookbaby.com/book/Logolounge-10"
              target="_blank"
              title="Buy LogoLounge 10 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>
        <article>
          <figure>
            <a
              href="http://www.amzn.com/1440340536/?tag=logoloucom-20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_9.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amzn.com/1440340536/?tag=logoloucom-20"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;9
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner &amp; Emily Potts</li>
              <li>Published: December 2015</li>
            </ul>
            <p>
              LogoLounge 9 once again celebrates expert identity work by notable designers
              and up-and-coming talents from around the world. This edition's far-reaching
              collection offers inspiration, insight, and an indispensable reference tool
              for graphic designers and their clients. Masterminded by Bill Gardner,
              president of Gardner Design, the LogoLounge.com website showcases the latest
              international logo creations!
            </p>

            <a
              className="button"
              href="http://www.amzn.com/1440340536/?tag=logoloucom-20"
              target="_blank"
              title="Buy LogoLounge 10 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="http://www.amzn.com/1592538347/?tag=logoloucom-20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_8.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amzn.com/1592538347/?tag=logoloucom-20"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;8
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner &amp; Anne Hellman</li>
              <li>Published: July 2014</li>
            </ul>
            <p>
              LogoLounge Book 8 has arrived! This edition's far-reaching collection offers
              inspiration, insight, and an indispensable reference tool for graphic
              designers and their clients. LogoLounge Book 8 presents the best logo
              designs posted to the website during the past year, as judged by a select
              group of top designers. Nine designer profiles and 2000 logos broken into 20
              visual categories await.
            </p>

            <a
              className="button"
              href="http://www.amzn.com/1592538347/?tag=logoloucom-20"
              target="_blank"
              title="Buy LogoLounge 8 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="http://www.amzn.com/1592537278/?tag=logoloucom-20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_7.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amzn.com/1592537278/?tag=logoloucom-20"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;7
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner &amp; Anne Hellman</li>
              <li>Published: July 2012</li>
            </ul>
            <p>
              The seventh book in the LogoLounge series once again celebrates the best
              identity work by top designers and rising talents from around the world.
              Features recent work by such world-renown firms as Pentagram, Lippincott,
              Turner Duckworth, and The Brand Union; emerging agencies venturethree,
              Dragon Rouge, and Carbone Smolan; and stand-alone artists Louise Fili and
              Chris Mitchell.
            </p>

            <a
              className="button"
              href="http://www.amzn.com/1592537278/?tag=logoloucom-20"
              target="_blank"
              title="Buy LogoLounge 7 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="http://www.amzn.com/1592536182/?tag=logoloucom-20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_6.gif"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amzn.com/1592536182/?tag=logoloucom-20"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;6
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: February 2011</li>
            </ul>
            <p>
              LogoLounge: Book 6 brings you 2,000 totally new logos from designers
              worldwide plus articles on inspiring identity projects from Lippincott,
              Felix Sockwell, Fragile, Cato Purnell Partners, Mattson Creative, Moving
              Brands, Husbosch, Interbrand, Duffy &amp; Partners, Gardner Design and more.
            </p>

            <a
              className="button"
              href="http://www.amzn.com/1592538347/?tag=logoloucom-20"
              target="_blank"
              title="Buy LogoLounge 6 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a href="http://amzn.to/ahHCxH" target="_blank" rel="noreferrer">
              <img
                src="https://www.logolounge.com/images/ll-books/llb_5.gif"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a href="http://amzn.to/ahHCxH" target="_blank" rel="noreferrer">
                LogoLounge Book&nbsp;5
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: July 2009</li>
            </ul>
            <p>
              LogoLounge: Book 5 brings you 2,000 innovative logo designs plus articles on
              inspiring identity projects from Lippincott, Felix Sockwell, Fragile, Cato
              Purnell Partners, Mattson Creative, Moving Brands, Husbosch, Interbrand,
              Duffy &amp; Partners, Gardner Design and more.
            </p>

            <a
              className="button"
              href="http://amzn.to/ahHCxH"
              target="_blank"
              title="Buy LogoLounge 5 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a href="http://amzn.to/bxsJqt" target="_blank" rel="noreferrer">
              <img
                src="https://www.logolounge.com/images/ll-books/llb_4.gif"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a href="http://amzn.to/bxsJqt" target="_blank" rel="noreferrer">
                LogoLounge Book&nbsp;4
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: July 2009</li>
            </ul>
            <p>
              LogoLounge: Book 4 brings together 2,000 thought-provoking logos plus
              articles on high-profile identity projects from Pentagram, Siegel+Gale,
              Jager Di Paola Kemp, Hesse Design, Interbrand, Karl Design, Iconologic,
              Shift Design and many more.
            </p>

            <a
              className="button"
              href="http://amzn.to/bxsJqt"
              target="_blank"
              title="Buy LogoLounge 4 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="http://www.amazon.com/gp/product/1592534368?ie=UTF8&amp;ref_=sr_1_8&amp;s=books&amp;qid=1275583133&amp;sr=8-8&amp;linkCode=shr&amp;camp=213733&amp;creative=393181&amp;tag=logoloucom-20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_3.gif"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amazon.com/gp/product/1592534368?ie=UTF8&amp;ref_=sr_1_8&amp;s=books&amp;qid=1275583133&amp;sr=8-8&amp;linkCode=shr&amp;camp=213733&amp;creative=393181&amp;tag=logoloucom-20"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Book&nbsp;3
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: July 2008</li>
            </ul>
            <p>
              LogoLounge: Book 3 delivers 2,000 inspiring logos plus articles on exciting
              logo design projects from Desgrippes Gobé, Armstrong International,
              Lippincott, FutureBrand, Wolff Olins, Turner Duckworth, Werner Design Werks,
              Carbone Smolan, Michael Osborne Design, Miles Newlyn, and more.
            </p>

            <a
              className="button"
              href="http://www.amazon.com/gp/product/1592534368?ie=UTF8&amp;ref_=sr_1_8&amp;s=books&amp;qid=1275583133&amp;sr=8-8&amp;linkCode=shr&amp;camp=213733&amp;creative=393181&amp;tag=logoloucom-20"
              target="_blank"
              title="Buy LogoLounge 3 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a href="http://amzn.to/bbq8PX" target="_blank" rel="noreferrer">
              <img
                src="https://www.logolounge.com/images/ll-books/llb_2_mini.gif"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a href="http://amzn.to/bbq8PX" target="_blank" rel="noreferrer">
                LogoLounge Book&nbsp;2
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: September 2007</li>
            </ul>
            <p>
              All of the 2,000 logos from LogoLounge: Mini Book 2 can be found in this
              compact (6.5 x 5.5-inch) soft cover version. It's the ideal tool for
              thumbing through and feeding the creative brain.
            </p>

            <a
              className="button"
              href="http://amzn.to/bbq8PX"
              target="_blank"
              title="Buy LogoLounge 2 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a href="http://amzn.to/crklFT" target="_blank" rel="noreferrer">
              <img
                src="https://www.logolounge.com/images/ll-books/llb_1_mini.gif"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a href="http://amzn.to/crklFT" target="_blank" rel="noreferrer">
                LogoLounge Book&nbsp;1
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: February 2006</li>
            </ul>
            <p>
              When you need quick inspirational nourishment, this mini version of
              LogoLounge: Mini Book 1 (now out of print) shares just the 2,000 logos from
              the first book in what has become a top-selling line of logo design
              collections.
            </p>

            <a
              className="button"
              href="http://amzn.to/crklFT"
              target="_blank"
              title="Buy LogoLounge 1 Book"
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>
      </div>
    </div>
  );
};

export default AllBooks;
