export const getCountries = () => {
  return [
    { value: 'US', name: 'United States' },
    { value: 'AE', name: 'United Arab Emirates' },
    { value: 'AL', name: 'Albania' },
    { value: 'AM', name: 'Armenia' },
    { value: 'AR', name: 'Argentina' },
    { value: 'AT', name: 'Austria' },
    { value: 'AU', name: 'Australia' },
    { value: 'BA', name: 'Bosnia and Herzegovina' },
    { value: 'BE', name: 'Belgium' },
    { value: 'BG', name: 'Bulgaria' },
    { value: 'BH', name: 'Bahrain' },
    { value: 'BN', name: 'Brunei Darussalam' },
    { value: 'BR', name: 'Brazil' },
    { value: 'BY', name: 'Belarus' },
    { value: 'CA', name: 'Canada' },
    { value: 'CH', name: 'Switzerland' },
    { value: 'CL', name: 'Chile' },
    { value: 'CN', name: 'China' },
    { value: 'CO', name: 'Colombia' },
    { value: 'CR', name: 'Costa Rica' },
    { value: 'CU', name: 'Cuba' },
    { value: 'CY', name: 'Cyprus' },
    { value: 'CZ', name: 'Czech Republic' },
    { value: 'DE', name: 'Germany' },
    { value: 'DK', name: 'Denmark' },
    { value: 'DO', name: 'Dominican Republic' },
    { value: 'EC', name: 'Ecuador' },
    { value: 'EE', name: 'Estonia' },
    { value: 'EG', name: 'Egypt' },
    { value: 'ES', name: 'Spain' },
    { value: 'FI', name: 'Finland' },
    { value: 'FR', name: 'France' },
    { value: 'GE', name: 'Georgia' },
    { value: 'GR', name: 'Greece' },
    { value: 'HK', name: 'Hong Kong' },
    { value: 'HR', name: 'Croatia' },
    { value: 'HU', name: 'Hungary' },
    { value: 'ID', name: 'Indonesia' },
    { value: 'IE', name: 'Ireland' },
    { value: 'IL', name: 'Israel' },
    { value: 'IN', name: 'India' },
    { value: 'IR', name: 'Iran' },
    { value: 'IS', name: 'Iceland' },
    { value: 'IT', name: 'Italy' },
    { value: 'JO', name: 'Jordan' },
    { value: 'JP', name: 'Japan' },
    { value: 'KR', name: 'Korea' },
    { value: 'KW', name: 'Kuwait' },
    { value: 'KZ', name: 'Kazakhstan' },
    { value: 'LB', name: 'Lebanon' },
    { value: 'LI', name: 'Liechtenstein' },
    { value: 'LT', name: 'Lithuania' },
    { value: 'LU', name: 'Luxembourg' },
    { value: 'LV', name: 'Latvia' },
    { value: 'MK', name: 'Macedonia' },
    { value: 'MO', name: 'Macao' },
    { value: 'MT', name: 'Malta' },
    { value: 'MU', name: 'Mauritius' },
    { value: 'MX', name: 'Mexico' },
    { value: 'MY', name: 'Malaysia' },
    { value: 'NG', name: 'Nigeria' },
    { value: 'NL', name: 'Netherlands' },
    { value: 'NO', name: 'Norway' },
    { value: 'NZ', name: 'New Zealand' },
    { value: 'OM', name: 'Oman' },
    { value: 'PA', name: 'Panama' },
    { value: 'PE', name: 'Peru' },
    { value: 'PH', name: 'Philippines' },
    { value: 'PK', name: 'Pakistan' },
    { value: 'PL', name: 'Poland' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'PT', name: 'Portugal' },
    { value: 'PY', name: 'Paraguay' },
    { value: 'QA', name: 'Qatar' },
    { value: 'RO', name: 'Romania' },
    { value: 'RS', name: 'Serbia' },
    { value: 'RU', name: 'Russian Federation' },
    { value: 'SA', name: 'Saudi Arabia' },
    { value: 'SE', name: 'Sweden' },
    { value: 'SG', name: 'Singapore' },
    { value: 'SI', name: 'Slovenia' },
    { value: 'SK', name: 'Slovakia' },
    { value: 'TH', name: 'Thailand' },
    { value: 'TR', name: 'Turkey' },
    { value: 'TW', name: 'Taiwan' },
    { value: 'UA', name: 'Ukraine' },
    { value: 'GB', name: 'United Kingdom' },
    { value: 'UY', name: 'Uruguay' },
    { value: 'VE', name: 'Venezuela' },
    { value: 'VI', name: 'Virgin Islands, U.S.' },
    { value: 'VN', name: 'Viet Nam' },
    { value: 'YT', name: 'Mayotte' },
    { value: 'ZA', name: 'South Africa' },
  ];
};

export interface IComments {
  avatar_url: string;
  company: string;
  first_name: string;
  last_name: string;
  logo_comment_id: number;
  designer_id: number;
  slug: string;
  comment: string;
  date_added: string;
}

export const orderCommentsByDate = (object: IComments[]) => {
  if (object) {
    return object.sort((a, b) => {
      const dateA = new Date(a.date_added);
      const dateB = new Date(b.date_added);
      return dateB.getTime() - dateA.getTime();
    });
  }
  return [];
};

export function getTimeDifference(commentDate: string): string {
  const commentDateTime = new Date(commentDate);
  const currentDate = new Date();

  const timeDifferenceInMilliseconds = currentDate.getTime() - commentDateTime.getTime();
  const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
  const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
  const timeDifferenceInHours = timeDifferenceInMinutes / 60;
  const timeDifferenceInDays = timeDifferenceInHours / 24;
  const timeDifferenceInWeeks = timeDifferenceInDays / 7;
  const timeDifferenceInYears = timeDifferenceInDays / 365;

  const timeUnits = [
    { value: timeDifferenceInYears, unit: 'y' },
    { value: timeDifferenceInWeeks, unit: 'w' },
    { value: timeDifferenceInDays, unit: 'd' },
    { value: timeDifferenceInHours, unit: 'h' },
    { value: timeDifferenceInMinutes, unit: 'm' },
  ];

  const result = timeUnits.find(unit => unit.value >= 1);

  if (result) {
    const { value, unit } = result;
    return `${Math.floor(value)}${unit}`;
  } else {
    return 'Just now';
  }
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function formatDateTime(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate().toString();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  const hours = (date.getHours() % 12 || 12).toString();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  return `Published ${month} ${day}, ${year} · ${hours}:${minutes} ${ampm}`;
}

export const getDate = (
  dateString: string,
  format: 'long' | 'short' = 'short'
): string => {
  if (dateString.trim() === '') {
    return dateString;
  }

  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    if (format === 'long') {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(date);
    } else {
      return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(
        date
      );
    }
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

export const fullUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  return url;
};
export const formatUrl = (url: string) => {
  let strippedUrl = url.replace(/^https?:\/\//, '');
  strippedUrl = strippedUrl.replace(/^www\./, '');
  strippedUrl = strippedUrl.replace(/\/$/, '');

  return strippedUrl;
};

export const deleteValidation = (upload_date: string) => {
  const uploadDate = new Date(upload_date);
  const now = new Date();
  const timeDifference = now.getTime() - uploadDate.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  if (hoursDifference < 48) {
    return true;
  }
  return false;
};
