import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout from '../components/molecules/Layout';
import clsx from 'clsx';
import Logo from '../components/atoms/Logo/Logo';
import CollectionDetailModal from '../components/features/Modals/CollectionDetailModal';
import { ICollection } from '../types/collection.types';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getCollections } from '../services/collections';
import { getProfileInfo } from '../services/designer';
import { IProfileInfo } from '../types/accountSettings.types';
import { ILogo } from '../types/logos.types';
import { SKELETON_ITEMS } from '../utils/dummyData';
import Skeleton from '../components/atoms/Skeleton';
import useAuth from '../hooks/useAuth';
import useModal from '../hooks/useModal';
import useToast from '../hooks/useToast';

const Collection = () => {
  const { designerId: myId } = useAuth();
  const toast = useToast();
  const { collectionId } = useParams();

  const { closeModal, openModal, isModalOpen } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [profileInfo, setProfileInfo] = useState<IProfileInfo>();
  const [collection, setCollection] = useState<ICollection>();
  const [isMyCollection, setIsMyCollection] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (searchParams.has('logo_id')) {
      openModal();
    }
  }, [searchParams, collection?.logos]);

  useEffect(() => {
    document.title = 'Collection on LogoLounge - Work';
  }, []);

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like') {
      if (collection) {
        const newLogos = collection.logos?.map((logo: ILogo) => {
          if (logo.logo_id === logoId) {
            return {
              ...logo,
              is_liked: params.value,
            };
          } else {
            return logo;
          }
        });
        setCollection({
          ...collection,
          logos: newLogos,
        });
      }
    } else if (action === 'save' && collection) {
      if (params.value && params.value === 1) {
        loadCollection();
      } else {
        const newLogos = collection.logos?.filter(
          (logo: ILogo) => logo.logo_id !== logoId
        );
        setCollection({
          ...collection,
          logos: newLogos,
        });
      }
    }
  };

  const loadCollection = useCallback(() => {
    setIsLoading(true);

    getCollections({ collection_id: collectionId as unknown as number })
      .then(data => {
        if (data) {
          setIsMyCollection(data.designer_id === myId);
          setCollection(data);
          document.title = data.name + ' - Collection on LogoLounge - Work';
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error getting collection.',
          });
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error getting collection info:', error);
      });
  }, [collectionId, myId]);

  useEffect(() => {
    loadCollection();
  }, [loadCollection]);

  const loadProfileInfo = useCallback(() => {
    if (collection?.designer_id) {
      getProfileInfo(collection?.designer_id).then(data => {
        if (data) {
          setProfileInfo(data);
        }
      });
    }
  }, [collection?.designer_id]);

  useEffect(() => {
    loadProfileInfo();
  }, [loadProfileInfo]);

  return (
    <Layout className={clsx('collection')}>
      <main id="content">
        <div className="content_outer wide">
          <div className="page_header_outer">
            <header className="page_header">
              <div>
                <h1 className="name">{collection?.name}</h1>
                <div className="info">
                  {profileInfo && (
                    <>
                      by{' '}
                      <a href={`/designers/${collection?.slug}?tab=logos`}>
                        {profileInfo?.company}
                      </a>{' '}
                      ·
                    </>
                  )}
                  {collection?.logos?.length && (
                    <span>
                      {collection?.logos?.length} logo
                      {collection?.logos?.length !== 1 && 's'}
                    </span>
                  )}
                </div>
              </div>
              {isMyCollection && (
                <div className="page_actions">
                  <Link
                    className="gray_button"
                    to="/collection-edit"
                    state={{ collectionId: collectionId }}
                  >
                    Edit Collection
                  </Link>
                </div>
              )}
            </header>
          </div>
          <div className="logos_outer">
            <ul>
              {collection?.logos?.map((logo, index) => (
                <li key={`logo-collection_${index}}`}>
                  <Logo
                    logo={logo}
                    section="collection"
                    index={index}
                    actionCallback={actionCallback}
                    logoRef={divRef}
                  />
                </li>
              ))}
              {isLoading &&
                Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                  <li key={`logo-skeleton-${index}`}>
                    <article>
                      <Skeleton width={width} height={width} borderRadius={10} />
                    </article>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {isModalOpen && (
          <CollectionDetailModal
            closeDetailModal={closeModal}
            logos={collection?.logos || []}
            actionCallback={actionCallback}
          />
        )}
      </main>
    </Layout>
  );
};

export default Collection;
