import React from 'react';
import { Link } from 'react-router-dom';
import LogoCollection from './LogoCollection';
import { ICollection } from '../../../../types/collection.types';

const CollectionCard = ({
  index,
  collection,
}: {
  index: number;
  collection: ICollection;
}) => {
  return (
    <Link
      key={index}
      className="collection_card"
      to={`/collection/${collection.light_box_id}`}
    >
      <span className="logo_grid">
        {collection.logo_urls_list && (
          <LogoCollection logo_urls_list={collection.logo_urls_list} />
        )}
      </span>
      <span className="collection_summary">
        <span className="title">
          <h5>{collection.name}</h5>
        </span>
        <span className="caption">{collection.count} logos</span>
      </span>
    </Link>
  );
};

export default CollectionCard;
