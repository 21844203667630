import React, { ReactNode, useState } from 'react';
import { SpinnerContext } from '../context/Spinner';
import Loading from '../components/molecules/Loading';

const SpinnerProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setOpen] = useState(false);

  const open = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <SpinnerContext.Provider value={{ open, close }}>
      {isOpen && <Loading />}
      {children}
    </SpinnerContext.Provider>
  );
};

export default SpinnerProvider;
