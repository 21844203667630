import React, { ReactNode, useState } from 'react';
import { ModalContext } from '../context/Modal.context';

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState<'detail' | 'collection' | undefined>('detail');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setType(undefined);
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, setType, type }}>
      {children}
    </ModalContext.Provider>
  );
};
