import axios from '../api';

export const checkAuthStatus = async () => {
  try {
    const response = await axios.get('/api/auth/check');
    return response.data;
  } catch (error) {
    return null;
  }
};

export const login = async (username: string, password: string) => {
  try {
    const response = await axios.post('/api/auth/login', {
      username: username,
      password: password,
    });
    return response.data;
  } catch (error: any) {
    if(error.response?.status === 401){
      return error.response?.data;
    }
    return null;
  }
};

export const logout = async () => {
  try {
    const response = await axios.post('/api/auth/logout');
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updatePassword = async (old_password: string, new_password: string) => {
  try {
    const response = await axios.post('/api/auth/update-password', {
      old_password: old_password,
      new_password: new_password,
    });
    return response.data;
  } catch (error: any) {
    if(error.response?.status === 401){
      return error.response?.data;
    }
    return null;
  }
};

export const forgotPassword = async (username: string) => {
  try {
    const response = await axios.post('/api/auth/forgot-password', {
      username: username,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const resetPassword = async ({designer_id, token, new_password}: 
    {designer_id: string, token: string, new_password: string}) => {
  try {
    const response = await axios.post('/api/auth/reset-password', {
      designer_id: designer_id,
      token: token,
      new_password: new_password
    });
    return response.data;
  } catch (error) {
    return null;
  }
};