import React, { useEffect } from 'react';
import { getCountries } from '../../../../../utils/functions';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AsYouType } from 'libphonenumber-js';
import { contactInfoSchema } from '../../../../../schemas/account/contactInfo.schema';
import {
  IContactInfo,
  IContactInfoForm,
} from '../../../../../types/accountSettings.types';
import { updateContactInfo } from '../../../../../services/designer';
import useToast from '../../../../../hooks/useToast';
import SkeletonForm from './SkeletonForm';
import useSpinner from '../../../../../hooks/useSpinner';
const countries = getCountries();

const ContactInfo = ({
  contactInfo,
  isLoading,
}: {
  contactInfo: IContactInfo;
  isLoading: boolean;
}) => {
  const toast = useToast();
  const spinner = useSpinner();
  const { handleSubmit, register, reset, control } = useForm<IContactInfoForm>({
    resolver: yupResolver(contactInfoSchema),
  });

  const onSubmit = (values: IContactInfoForm) => {
    spinner.open();
    updateContactInfo({
      ...values,
      address1: values.streetAddress,
      address2: values.suite || '',
      city: values.addressLocality,
      zip: values.postalCode,
      state: values.addressRegion,
    })
      .then(response => {
        spinner.close();
        if(response){
          toast.addToast({
            type: 'success',
            message: 'Contact information updated!',
          });
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error updating contact information.',
          });
        }
      })
      .catch(error => {
        spinner.close();
        console.error('Error updating the contact form', error);
      });
  };

  useEffect(() => {
    reset({
      email: contactInfo?.email || '',
      phone: contactInfo?.phone || '',
      streetAddress: contactInfo?.address1 || '',
      suite: contactInfo?.address2 || '',
      addressLocality: contactInfo?.city || '',
      addressRegion: contactInfo?.state || '',
      postalCode: contactInfo?.zip || '',
      country: contactInfo?.country || '',
    });
  }, [contactInfo, reset]);

  return (
    <section>
      <div className="content_outer">
        <div id="contact-info">
          {isLoading ? (
            <SkeletonForm />
          ) : (
            <form className="account_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label className="control-label">Email</label>
                <input className="form-control" type="email" {...register('email')} />
              </div>

              <div className="form-group">
                <label className="control-label">Phone</label>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      className="form-control"
                      type="tel"
                      {...field}
                      onChange={e =>
                        field.onChange(new AsYouType().input(e.target.value))
                      }
                    />
                  )}
                />
              </div>
              <div className="form-group-wrap--street-suite">
                <div className="form-group">
                  <label className="control-label">Street Address</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('streetAddress')}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Suite</label>
                  <input className="form-control" type="text" {...register('suite')} />
                </div>
              </div>

              <div className="form-group-wrap--city-state-zip">
                <div className="form-group">
                  <label className="control-label">City</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('addressLocality')}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">State/Province</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('addressRegion')}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Postal Code</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('postalCode')}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label">Country/Region</label>
                <select className="form-control" {...register('country')}>
                  <option value="">Anywhere</option>
                  {countries.map(country => (
                    <option key={country.value} value={country.value}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <p className="footnote">
                  (Countries marked with ** do not allow PayPal payments. To renew, you
                  will need to{' '}
                  <a href="contact">contact LogoLounge Support</a>
                  .)
                </p>
              </div>

              <div className="button_outer">
                <button className="btn btn-primary" type="submit">
                  Update Contact Info
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
