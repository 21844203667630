import clsx from 'clsx';
import React, { useEffect } from 'react';
import Layout from '../components/molecules/Layout';
import LogoDetail from '../components/atoms/Logo/LogoDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Logo = () => {
  const { logoId } = useParams<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (logoId === undefined) {
      setTimeout(() => navigate('/'), 3000);
    }
  }, [logoId, navigate]);

  return (
    <Layout className={clsx('logo')}>
      <main id="content">
        <div className="content_outer wide">
          <LogoDetail logoId={parseInt(logoId || "")} logoIndex={0} actionCallback={undefined} />
        </div>
      </main>
    </Layout>
  );
};

export default Logo;
