import React, { useState, useRef, useEffect } from 'react';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  id: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, children, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.minHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : '0px';
      contentRef.current.style.paddingBottom = isOpen ? `30px` : '0px';
    }
  }, [isOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion ${isOpen ? 'open' : ''}`} id={id}>
      <h5 onClick={toggleAccordion}>{title}</h5>
      <div
        ref={contentRef}
        className="accordion_content"
        style={{
          overflow: 'hidden',
          transition: 'min-height 0.3s ease-in-out',
          maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '1px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
