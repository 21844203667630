import React, { useCallback, useEffect, useState } from 'react';
import Container from '../../../atoms/Container';
import CollectionCard from './CollectionCard';
import { getCollections } from '../../../../services/collections';
import { ICollection } from '../../../../types/collection.types';
import Skeleton from '../../../atoms/Skeleton';
import useToast from '../../../../hooks/useToast';

const CollectionContent = ({ designerId }: { designerId: number }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [collections, setCollections] = useState<ICollection[]>([]);

  const handleCollections = useCallback(() => {
    setIsLoading(true);
    getCollections({ designer_id: designerId })
      .then(data => {
        if(data){
          setCollections(data);
        } else {
          setCollections([]);
          toast.addToast({
            type: 'error',
            message: 'Error getting collections.',
          });
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error getting profile info:', error);
      });
  }, [designerId, toast]);

  useEffect(() => {
    handleCollections();
  }, [handleCollections]);

  return (
    <Container>
      <div className="collection_cards">
        {!isLoading
          ? collections.map((collection, index) => (
              <CollectionCard
                index={index}
                key={`key-item-${index}`}
                collection={collection}
              />
            ))
          : Array.from({ length: 5 }).map((_, index) => (
              <div className="collection_card" key={`collection-skeleton-${index}`}>
                <div className="logo_grid">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <Skeleton
                      key={`logo-skeleton-${index}`}
                      width={114}
                      height={114}
                      borderRadius={10}
                    />
                  ))}
                </div>
                <div className="collection_summary">
                  <Skeleton className={'title'} width={240} height={24} />
                  <Skeleton className="caption" width={50} height={16} />
                </div>
              </div>
            ))}
      </div>
    </Container>
  );
};

export default CollectionContent;
