import * as yup from 'yup';
import { phoneRegExp } from '../join.schema';

export const contactInfoSchema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
  streetAddress: yup.string().required(),
  suite: yup.string().nullable().notRequired(),
  addressLocality: yup.string().required(),
  addressRegion: yup.string().required(),
  postalCode: yup.string().required(),
  country: yup.string().required(),
});
