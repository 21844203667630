import clsx from 'clsx';
import React from 'react';
const TabItem = ({
  label,
  isActive,
  className,
  ...props
}: {
  label: React.ReactNode;
  isActive?: boolean;
  className?: string;
}) => {
  return (
    <div className={clsx('tab', isActive && 'active checked', className)} {...props}>
      {label}
    </div>
  );
};

export default TabItem;
