import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import useLogoLounge from '../../../hooks/useLogoLounge';
import useModal  from '../../../hooks/useModal';

interface IModal {
  type: 'collection' | 'detail' | 'normal' | undefined;
  header?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  saveMode: boolean;
  onClose: () => void;
  overlapping?: boolean;
  onPrevClick?: (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => void;
  onNextClick?: (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => void;
  isOpen?: boolean;
}

const Modal: React.FC<IModal> = ({
  children,
  header,
  actions,
  onClose,
  saveMode = false,
  type,
  overlapping,
  onPrevClick,
  onNextClick,
  isOpen,
}) => {
  const [visible, setVisible] = useState(false);
  const [delay, setDelay] = useState(false);
  const { hidePrev, hideNext } = useLogoLounge();
  const { isModalOpen } = useModal();

  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);
  const [moving, setMoving] = useState<boolean>(false);

  const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    setStartY(touch.clientY);
    setMoving(true);
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (!moving) return;
      const touch = e.touches[0];
      const moveX = touch.clientX - startX;
      var moveY = touch.clientY - startY;
      if (moveY < 0) {
        moveY = moveY * -1;
      }

      const threshold = 50; // umbral para determinar un deslizamiento
      const thresholdY = 30;
      if (moveX > threshold && moveY < thresholdY && onPrevClick) {
        onPrevClick(e);
        setMoving(false);
      } else if (moveX < -threshold && moveY < thresholdY && onNextClick) {
        onNextClick(e);
        setMoving(false);
      }
    },
    [startX, moving, onPrevClick, onNextClick]
  );

  const handleTouchEnd = useCallback(() => {
    setMoving(false);
  }, []);

  //set classes to see  modal animation
  useEffect(() => {
    if (isModalOpen || isOpen) {
      setVisible(true);
      setDelay(true);
      document.body.classList.add('freeze');
    } else {
      setVisible(false);
      setTimeout(() => {
        setDelay(false);
        document.body.classList.remove('freeze');
      }, 300);
    }
    return () => {
      setDelay(false);
      document.body.classList.remove('freeze');
    };
  }, [isModalOpen, isOpen]);

  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div
        className={clsx(
          'modal_shade',
          visible && 'loading visible',
          delay && 'delay',
          overlapping && 'overlapping'
        )}
      />
      <div
        onClick={onClose}
        className={clsx(
          'modal_close_button',
          visible && 'loading visible',
          delay && 'delay',
          overlapping && 'overlapping'
        )}
      >
        Close
      </div>
      <div
        className={clsx(
          `modal modal_${type === 'detail' ? 'logo' : 'save'}`,
          visible && 'loading visible',
          delay && 'delay',
          overlapping && 'overlapping'
        )}
      >
        <div
          className="modal_windows"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="modal_inner">
            <div className="modal_inner_background close" onClick={onClose} />
            <div className="modal_content">
              <header className="modal-heading">
                <h3 className="modal_title">{header}</h3>
              </header>
              <input
                type="checkbox"
                className="hidden-input"
                id="new_collection_toggle"
                checked={saveMode}
                readOnly={true}
              />
              <div className="modal-body">{children}</div>
              {type === 'collection' && <div className="modal-actions">{actions}</div>}
            </div>
          </div>
        </div>
      </div>

      <div
        className={clsx('modal_prev_button', hidePrev && 'hide')}
        onClick={onPrevClick}
      >
        Prev
      </div>
      <div
        className={clsx('modal_next_button', hideNext && 'hide')}
        onClick={onNextClick}
      >
        Next
      </div>
      <div className={clsx('modal_save_shade')}></div>
    </>,
    modalRoot
  );
};

export default Modal;
