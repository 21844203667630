import React from 'react';

const MasterLibrary = () => {
  return (
    <div className="books_group_outer" id="master-library">
      <h2>Master Library Series</h2>

      <div className="books_group">
        <article>
          <figure>
            <a
              href="http://www.amazon.com/gp/product/1592537642/ref=as_li_ss_tl?ie=UTF8&amp;tag=logoloucom-20&amp;linkCode=as2&amp;camp=217145&amp;creative=399373&amp;creativeASIN=1592537642"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_ml4.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amazon.com/gp/product/1592537642/ref=as_li_ss_tl?ie=UTF8&amp;tag=logoloucom-20&amp;linkCode=as2&amp;camp=217145&amp;creative=399373&amp;creativeASIN=1592537642"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Master Library Volume 4
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: February 2012</li>
            </ul>
            <p>
              Fourth and final installment in the series – Master Library 4: Type &amp;
              Calligraphy is a highly organized collection of 3,000 typographic logo
              designs. Interviews with such top-tier logo designers as Miles Newlyn,
              Jessica Hische, and Ken Barber provide insights on the values, traditions,
              and future of designing with typography.
            </p>

            <a
              className="button"
              href="http://www.amazon.com/gp/product/1592537642/ref=as_li_ss_tl?ie=UTF8&amp;tag=logoloucom-20&amp;linkCode=as2&amp;camp=217145&amp;creative=399373&amp;creativeASIN=1592537642"
              target="_blank"
              title=""
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="http://www.amazon.com/gp/product/1592536905/ref=as_li_ss_tl?ie=UTF8&amp;tag=logoloucom-20&amp;linkCode=as2&amp;camp=217145&amp;creative=399373&amp;creativeASIN=1592536905"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_ml3.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amazon.com/gp/product/1592536905/ref=as_li_ss_tl?ie=UTF8&amp;tag=logoloucom-20&amp;linkCode=as2&amp;camp=217145&amp;creative=399373&amp;creativeASIN=1592536905"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Master Library Volume 3
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: July 2011</li>
            </ul>
            <p>
              Third installment in the series – Master Library 3: Shape &amp; Symbols
              collects 3,000 original logo designs from the LogoLounge.com database and
              includes interviews from top designers including Steff Geissbuhler, Jerry
              Kuyper and Hans Hulsbosch.
            </p>

            <a
              className="button"
              href="http://www.amazon.com/gp/product/1592536905/ref=as_li_ss_tl?ie=UTF8&amp;tag=logoloucom-20&amp;linkCode=as2&amp;camp=217145&amp;creative=399373&amp;creativeASIN=1592536905"
              target="_blank"
              title=""
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a
              href="http://www.amzn.com/1592536123/?tag=logoloucom-20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_ml2.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amzn.com/1592536123/?tag=logoloucom-20"
                target="_blank"
                rel="noreferrer"
              >
                LogoLounge Master Library Volume 2
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: July 2010</li>
            </ul>
            <p>
              Second installment in the popular Master Library series, Master Library 2:
              Animals &amp; Mythology collects 3,000 outstanding very focused logo designs
              from the LogoLounge.com database and includes interviews with Tracy Sabin,
              Andreas Karl, Michael Vanderbyl, and Bill Gardner.
            </p>

            <a
              className="button"
              href="http://www.amzn.com/1592536123/?tag=logoloucom-20"
              target="_blank"
              title=""
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>

        <article>
          <figure>
            <a href="http://amzn.to/ahHCxH" target="_blank" rel="noreferrer">
              <img
                src="https://www.logolounge.com/images/ll-books/llb_ml1.gif"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a href="http://amzn.to/ahHCxH" target="_blank" rel="noreferrer">
                LogoLounge Master Library Volume 1
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Cathy Fishel &amp; Bill Gardner</li>
              <li>Published: January 2010</li>
            </ul>
            <p>
              First in an innovation four–book series, Master Library 1: Initials &amp;
              Crests gathers 3,000 initial and crest logo designs from the LogoLounge.com
              collection and includes portraits of Saffron, Sagmeister, Office, Landor,
              MetaDesign, FutureBrand and more.
            </p>

            <a
              className="button"
              href="http://amzn.to/ahHCxH"
              target="_blank"
              title=""
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>
      </div>
    </div>
  );
};

export default MasterLibrary;
