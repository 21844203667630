function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateRandomData(length: number) {
  const randomData = [];
  for (let i = 0; i < length; i++) {
    const numberOfElements = getRandomInt(1, 10);
    const elements = Array.from({ length: numberOfElements }, () => getRandomInt(1, 10));
    randomData.push({ elements });
  }
  return randomData;
}

export const SKELETON_ITEMS = 5;
