import React, { useState } from 'react';
import Layout from '../components/molecules/Layout';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../services/auth';
import useToast from '../hooks/useToast';

const ForgotPassword = () => {
  const toast = useToast();
  const { handleSubmit, register } = useForm<{ username: string }>();
  const [sent, setSent] = useState(false);

  const onSubmit = (values: { username: string }) => {
    const { username } = values;
    forgotPassword(username).then((response) => {
      if(response === "Finished"){
        setSent(true);
      } else {
        toast.addToast({
          type: 'error',
          message: 'Error: Could not send password reset email.',
        });
      }
    })
  };

  return (
    <Layout className="login has_right_background">
      <main id="content">
        <figure></figure>
        <article>
          <div className="content_outer">
            <h1>Forgot Password</h1>
            <div className="submenu submenu-login">
              <div className="submenu_inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type="hidden"
                    name="continue"
                    value="https://www.logolounge.com/articles/2021logoloungetrendreport"
                  />
                  <div className="form-group form-group--required">
                    <label className="control-label">Username / Email</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Username / Email"
                      autoCapitalize="none"
                      autoCorrect="off"
                      spellCheck="false"
                      {...register('username')}
                    />
                  </div>

                  {sent ? (
                    <button disabled className="btn login-btn">
                      Email Sent!
                    </button>
                  ):(
                    <button className="btn btn-primary login-btn" type="submit">
                      Send
                    </button>
                  )}
                  <div className="container-btn">
                    <Link
                      className="forgot_password"
                      to="/login"
                      title="Request your password"
                    >
                      Login
                    </Link>
                    <Link className="become_a_member" to="/join" title="Become a member">
                      Not a member?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default ForgotPassword;
