import React from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const FooterRibbon = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  if (!isAuthenticated && currentPath !== '/join' && currentPath !== '/checkout')
    return (
      <div className="footer-ribbon">
        <div className="footer-ribbon-inner">
          <div className="text">
            <p>
              <strong>Make your mark</strong> <span>on logo history.</span>
            </p>
            <a href="/join" className="button">
              Join LogoLounge
            </a>
          </div>
        </div>
      </div>
    );
  return <></>;
};

export default FooterRibbon;
