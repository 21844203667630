import React, { ReactNode, useCallback, useState } from 'react';
import { ToastContext, ToastMessage } from '../context/Toast.context';
import { v4 as uuidv4 } from 'uuid';
import closeIcon from '../assets/images/icon/x_gray.svg';

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, message, duration = 5000 }: Omit<ToastMessage, 'id'>) => {
      const id = uuidv4();
      setToasts(prevToasts => [...prevToasts, { id, type, message, duration }]);
      setTimeout(() => removeToast(id), duration);
    },
    // eslint-disable-next-line
    []
  );

  const removeToast = useCallback((id: string) => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {/* Toast container to render toasts */}
      <div className="toastContainer">
        {toasts.map(toast => (
          <div key={toast.id} className={`toast ${toast.type}`}>
            {toast.message}
            <button className="closeButton" onClick={() => removeToast(toast.id)}>
              {' '}
              <img src={closeIcon} alt="close" />{' '}
            </button>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
