import React, { useEffect, useRef, useState } from 'react';
import Skeleton from '../../../../atoms/Skeleton';

const EditSkeleton = () => {
  const [width, setWidth] = useState<number | undefined>(undefined);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, []);
  return (
    <main id="content">
      <div className="content_outer wide">
        <form action="#" className="upload_form">
          <div className="image_group">
            <figure className="upload" ref={divRef}>
              <Skeleton
                width={width && width - 10}
                height={width && width - 50}
                borderRadius={10}
              />
            </figure>
          </div>

          <div className="data_group">
            <fieldset>
              <label>
                <Skeleton height={56} borderRadius={10} />
              </label>
            </fieldset>
            <fieldset>
              <label>
                <Skeleton height={56} borderRadius={10} />
              </label>
            </fieldset>

            <div className="group">
              <fieldset>
                <label>
                  <Skeleton height={56} borderRadius={10} />
                </label>
              </fieldset>
              <fieldset>
                <label>
                  <Skeleton height={56} borderRadius={10} />
                </label>
              </fieldset>
            </div>

            <fieldset>
              <label>
                <Skeleton height={120} borderRadius={10} />
              </label>
            </fieldset>

            <fieldset>
              <label>
                <Skeleton height={100} borderRadius={10} />
              </label>
            </fieldset>
          </div>
        </form>
      </div>
    </main>
  );
};

export default EditSkeleton;
