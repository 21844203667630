export const faqs = [
  {
    id: 'faq1',
    question: 'How are logos judged?',
    answer:
      'A group of 8 to 10 logo design giants and geniuses make up the jury for each LogoLounge Book. Each logo is scrutinized by 3 jurors who score it based on their impression. Each logo’s cumulative score then determines whether or not it makes it into the book.',
  },
  {
    id: 'faq2',
    question: 'Is there a limit on the number of logos I can submit?',
    answer:
      'Nope. LogoLounge members can submit as many logos as they want. Keep in mind that more logos doesn’t mean more wins, especially multiple iterative logo variations.',
  },
  {
    id: 'faq3',
    question: 'When’s the best time to submit my logos?',
    answer:
      'In a word, now. All logos in the competition are judged in random order. Every logo on LogoLounge is submitted for a book, and, even after Book 13’s entry deadline, logos can be submitted for Book 14.',
  },
  {
    id: 'faq4',
    question: 'How do I submit my logos?',
    answer:
      'If you’re a LogoLounge member, go to the Submit page. Each logo you upload is submitted into the competition for the next upcoming LogoLounge Book. Not a member yet? Join today and start submitting.',
  },
  {
    id: 'faq5',
    question: 'What file format is accepted?',
    answer:
      '900 pixels square. JPG, PNG, or GIF. You must own the rights to the media you upload.',
  },
  {
    id: 'faq6',
    question: 'Do the logos have to be designed since the last book?',
    answer:
      'No, there are no requirements for when the logo was designed; just when it was submitted to LogoLounge. Of course, don’t submit a logo more than once.',
  },
  {
    id: 'faq7',
    question: 'Can the logo be conceptual/spec/fantasy work?',
    answer: 'Absolutely. At LogoLounge, we’re inspired by all kinds.',
  },
  {
    id: 'faq8',
    question: 'When will I know if my logo made the cut?',
    answer:
      'Typically, about 3 months after the entry deadline. At that point we’ll notify you by email if your work is selected.',
  },
];

export const books = [
  {
    id: 'book1',
    question: 'How are the firms selected for the case studies in the books?',
    answer:
      'The projects in the book are selected for their quality, timeliness, relevance, and inspirational nature. We aim to form an effective mix of large and smaller firms, geographies, budgets, and client types.',
  },
  {
    id: 'book2',
    question: 'When do new books come out?',
    answer: 'The LogoLounge book series releases a new edition about every 1 1/2 years.',
  },
  {
    id: 'book3',
    question: 'How do I purchase a book?',
    answer:
      'All LogoLounge books are available on Amazon.com. Check out our book list on our Books page.',
  },
];

export const account = [
  {
    id: 'account1',
    question: 'How do I update my contact info?',
    answer:
      'Go to your Account page to make changes. Email is the primary way we’ll contact you, so please keep this up to date.',
  },
  {
    id: 'account2',
    question: 'Where’s my following feed?',
    answer:
      'Find the feed of logos by designers you’ve followed on your Private Logo Gallery.',
  },
  {
    id: 'account3',
    question: 'Where can I find my liked logos?',
    answer: 'Find all your liked logos in one place on your Favorites page.',
  },
  {
    id: 'account4',
    question: 'Can I save my favorite logos?',
    answer:
      'Why, certainly. You can save logos on the site to Collections—your own public or private favorites. Curate your Collections to fit exactly the inspiration you need for any of your design projects, plus see what’s inspiring other designers, too.',
  },
];
