import React, { useEffect, useState } from 'react';
import Container from '../../../atoms/Container';
import SubscribeForm from '../../SubscribeForm';
import { getFeaturePost, getRegularPosts } from '../../../../services/wordpress';
import { formatDate } from '../../../../utils/functions';
import useToast from '../../../../hooks/useToast';


interface wpPost {
  id: number;
  title: wpRenderedContent;
  author: number;
  acf: wpACF;
  excerpt: wpRenderedContent;
  link: string;
  date: string | null;
  _embedded: any;
}

interface wpRenderedContent {
  protected?: boolean;
  rendered: string;
}

interface wpACF {
  author_name: string;
}

const NewsContent = () => {
  const toast = useToast();
  const sections: string[] = ['Section 1', 'Logo News'];

  const [featurePost, setFeaturePost] = useState<wpPost>();
  const [sidePosts, setSidePosts] = useState<wpPost[]>();
  const [posts, setPosts] = useState<wpPost[]>();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const getImgUrl = (post: any) => {
    const sizes = post._embedded['wp:featuredmedia'][0]['media_details'].sizes;
    return (
      sizes.large?.source_url ||
      sizes.medium?.source_url ||
      sizes.medium_large?.source_url
    );
  };

  useEffect(() => {
    getFeaturePost().then(feature => {
      if(feature){
        setFeaturePost(feature);
      } else {
        toast.addToast({
          type: 'error',
          message: 'Could not load feature post.',
        });
      }
    });
    getRegularPosts({per_page: 3, offset: 0}).then(posts => {
      if(posts){
        setSidePosts(posts.data);
      }
    });
  }, []);

  useEffect(() => {
    getRegularPosts({per_page: 6, offset: 3 + (page-1) * 6}).then(posts => {
      if(posts){
        setMaxPage(posts.headers["x-wp-totalpages"]);
        setPosts(posts.data);
      } else {
        toast.addToast({
          type: 'error',
          message: 'Could not load posts.',
        });
      }
    });
  }, [page]);

  return (
    <Container className="clear">
      <div>
        {sections.map((section, h) => {
          const id = section.toLowerCase().replace(' ', '-');

          return (
            <div className="article_group_outer" key={h} id={h === 0 ? id : undefined}>
              {h === 0 && (
                <div className="article_group_a">
                  <article
                    className="article"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <figure className="image">
                      <a href={featurePost?.link || ''} target="_blank" rel="noreferrer">
                        <img
                          itemProp="thumbnailUrl"
                          src={
                            featurePost?._embedded &&
                            featurePost._embedded['wp:featuredmedia'][0]['media_details']
                              .sizes.large.source_url
                          }
                          alt=""
                        />
                      </a>
                    </figure>
                    <div className="text">
                      <div className="category">Feature</div>
                      <p itemProp="headline">
                        <a
                          href={featurePost?.link || ''}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {featurePost?.title?.rendered}
                        </a>
                      </p>
                      <div className="caption">
                        <span itemProp="datePublished">
                          {featurePost?.date && formatDate(featurePost.date)}
                        </span>
                      </div>
                      <p
                        itemProp="description"
                        dangerouslySetInnerHTML={{
                          __html: featurePost?.excerpt?.rendered || '',
                        }}
                      ></p>
                    </div>
                  </article>
                  <div className="side_articles">
                    {sidePosts &&
                      sidePosts.map((post, i) => (
                        <article
                          className="article"
                          itemScope
                          itemType="http://schema.org/Article"
                          key={i}
                        >
                          <figure className="image">
                            <a href={post.link} target="_blank" rel="noreferrer">
                              <img itemProp="thumbnailUrl" src={getImgUrl(post)} alt="" />
                            </a>
                          </figure>
                          <div className="text">
                            <div className="category">Logo News</div>
                            <p itemProp="headline">
                              <a href={post.link} target="_blank" rel="noreferrer">
                                {post.title.rendered}
                              </a>
                            </p>
                            <div className="caption">
                              <span itemProp="datePublished">
                                {post.date && formatDate(post.date)}
                              </span>
                            </div>
                            <p
                              itemProp="description"
                              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                            ></p>
                          </div>
                        </article>
                      ))}
                  </div>
                </div>
              )}

              {h !== 0 && (
                <div className="article_group_b">
                  {posts &&
                    posts.map((post, i) => (
                      <article
                        className="article"
                        itemScope
                        itemType="http://schema.org/Article"
                        key={i}
                      >
                        <figure className="image">
                          <a href={post.link} target="_blank" rel="noreferrer">
                            <img itemProp="thumbnailUrl" src={getImgUrl(post)} alt="" />
                          </a>
                        </figure>
                        <div className="text">
                          <div className="category">Logo News</div>
                          <p itemProp="headline">
                            <a href={post.link} target="_blank" rel="noreferrer">
                              {post.title.rendered}
                            </a>
                          </p>
                          <div className="caption">
                            <span itemProp="datePublished">
                              {post.date && formatDate(post.date)}
                            </span>
                          </div>
                          <p
                            itemProp="description"
                            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                          ></p>
                        </div>
                      </article>
                    ))}
                </div>
              )}
              
            </div>
          );
        })}
      </div>

      <div className="news_pagination_outer">
        <div className="page_button_outer next_button_outer">
          {page < maxPage && (
            <a href="https://www.logolounge.com/news" className="button next_button">
              More
            </a>
          )}
        </div>
      </div>
    </Container>
  );
};

export default NewsContent;
