import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { IContactForm } from '../types/contactForm.types';
import { contactSchema } from '../schemas/contact.schema';
import { submitContactForm } from '../services/contact';
import useToast from '../hooks/useToast';

const Contact = () => {
  useEffect(() => {
    document.title =
      'Contact - LogoLounge - Logo Design Inspiration & Competition - Work';
  }, []);
  const [message, setMessage] = useState('');
  const toast = useToast();

  const { handleSubmit, register } = useForm<IContactForm>({
    resolver: yupResolver(contactSchema),
  });

  const onSubmit = (values: IContactForm) => {
    submitContactForm(values)
      .then(response => {
        if (response === 'success') {
          toast.addToast({
            type: 'success',
            message: 'Message sent 📤!',
          });
        } else {
          // TODO - Better messages?
          toast.addToast({
            type: 'error',
            message: 'There was an error, try again later.',
          });
        }
      })
      .catch(error => {
        toast.addToast({
          type: 'error',
          message: 'There was an error, try again later.',
        });
      });
  };

  return (
    <Layout className={clsx('support has_left_background')} showRibbon={true}>
      <main id="content">
        <figure></figure>
        <article
          className="article"
          itemScope={false}
          itemType="http://schema.org/Article"
        >
          <div className="content_outer">
            <h1>Contact</h1>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="support_form"
              method="post"
              action=""
            >
              <input type="hidden" name="User" value="0" />

              <input className="hidden-input" type="hidden" name="required" />

              <div className="form-group">
                <label className="control-label">Name</label>
                <input className="form-control" type="text" {...register('name')} />
              </div>

              <div className="form-group">
                <label className="control-label">Email</label>
                <input className="form-control" type="email" {...register('email')} />
              </div>

              <div className="form-group">
                <label className="control-label">Subject</label>
                <select className="form-control" {...register('subject')}>
                  <option>General Contact</option>
                  <option>Account Help</option>
                </select>
              </div>

              <p id="bug_report_message" className="field_message hidden">
                Got a problem with the website? For starters, try{' '}
                <a href="logout">logging out</a> and then logging back in again.
              </p>

              <div className="form-group">
                <label className="control-label">Message</label>
                <textarea
                  required
                  className="form-control"
                  placeholder="How can we help?"
                  {...register('message')}
                ></textarea>
              </div>

              <div className="button_outer">
                <button className="btn btn-primary btn--has-arrow">Send Message</button>
              </div>
              <div className="button_outer">{message}</div>
            </form>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default Contact;
