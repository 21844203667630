import * as yup from 'yup';

export const SubmitLogoSchema = yup.object().shape({
  filename: yup.string(),
  file_base64: yup.string(),
  title: yup.string().required(),
  description: yup.string(),
  art_director: yup.string(),
  creative_director: yup.string(),
  client: yup.string(),
  industry: yup.string(),
  logotype: yup.string().required().oneOf(['Symbol', 'Typographic', 'Combo']),
  tags: yup.string(),
});
