import React from 'react';
import Layout from '../components/molecules/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from '../schemas/resetPassword.schema';
import { resetPassword } from '../services/auth';
import useToast from '../hooks/useToast';

const PasswordReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const designer_id = new URLSearchParams(location.search).get("user");
  const token = new URLSearchParams(location.search).get("token");

  React.useEffect(() => {
    if(!designer_id || !token){
      navigate('/login');
    }
  }, [])

  const { handleSubmit, register, formState: { errors } } = useForm<{
    new_password: string;
    confirm_password: string;
  }>({ resolver: yupResolver(resetPasswordSchema) });

  const onSubmit = (values: { new_password: string; confirm_password: string }) => {
    const { new_password, confirm_password } = values;
    if (new_password === confirm_password && designer_id && token) { // should already be true but just in case
      resetPassword({designer_id, token, new_password}).then((response) => {
        if(response === "Updated"){
          toast.addToast({
            type: 'success',
            message: 'Password updated successfully.',
          });
          navigate('/login');
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error resetting password.',
          });
        }
      })
    }
  };

  return (
    <Layout className="login has_right_background">
      <main id="content">
        <figure></figure>
        <article>
          <div className="content_outer">
            <h1>Forgot Password</h1>
            <section>
              <div id="edit-profile">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="profile_form"
                  encType="multipart/form-data"
                >
                  <div className="form-group-wrap--name">
                    <div className="form-group">
                      <label className="control-label">New Password</label>
                      <input
                        className="form-control"
                        type="password"
                        {...register("new_password")}
                      />
                      <span className="error_message">{errors.new_password?.message}</span>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Confirm Password</label>
                      <input
                        className="form-control"
                        type="password"
                        {...register("confirm_password")}
                      />
                      <span className="error_message">{errors.confirm_password?.message}</span>
                    </div>
                  </div>
                  <div className="button_outer">
                    <button className="btn btn-primary" type="submit">
                      Update Password
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default PasswordReset;
