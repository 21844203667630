import React, { useEffect } from 'react';
import Layout from '../components/molecules/Layout';
import Login from '../components/molecules/Submenu/Login/Login';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const LoginPage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Login to LogoLounge';
  
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Layout className="login has_right_background">
      <main id="content">
        <figure></figure>
        <article>
          <div className="content_outer">
            <h1>Login</h1>
            <Login />
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default LoginPage;
