import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import useAuth from '../../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

interface LayoutProps {
  className?: string;
  showRibbon?: boolean;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ className = '', showRibbon, children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (className) {
      const classList = className.split(' ');
      document.body.classList.add(...classList);
    }
    if (isAuthenticated) {
      document.body.classList.add('is_user');
    }

    setIsReady(true);

    return () => {
      if (className) {
        const classList = className.split(' ');
        document.body.classList.remove(...classList);
      }
      document.body.classList.remove('is_user');
    };
  }, [className, isAuthenticated]);

  useEffect(() => {
    const section = document.querySelector('#root-outer-div');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [pathname]);

  if (!isReady) {
    return null;
  }

  return (
    <div id="root-outer-div">
      <Header user={isAuthenticated} />
      <>{children}</>
      <div id="modal-root" />
      <div id="loading-root" />
      <Footer showRibbon={showRibbon} />
    </div>
  );
};

export default Layout;
