import axios from '../api';
import { IContactForm } from '../types/contactForm.types';

export const submitContactForm = async (values: IContactForm) => {
  try {
    const response = await axios.post('/api/contact', values);
    if (response.status >= 200 && response.status < 300) {
      return 'success';
    }
    return null;
  } catch (error) {
    return null;
  }
};
