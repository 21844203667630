import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_WP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getFeaturePost = async () => {
  try {
    const response = await instance.get('/posts?_embed', { params: {
      categories: 7,
      per_page: 1,
      page: 1
    }});
    if (response.status < 300) {
      return response.data[0];
    } 
    return null;
  } catch (error) {
    return null;
  }
};

export const getRegularPosts = async ({per_page, offset} : {per_page?: number, offset?: number}) => {
  try {
    const response = await instance.get('/posts?_embed', { params: {
      categories: 1,
      per_page: per_page || 6,
      offset: offset || 0
    }});
    if (response.status < 300) {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

