import React from 'react';
import Skeleton from '../../../../atoms/Skeleton';

const SkeletonForm = () => {
  return (
    <div className="account_form">
      <div className="form-group">
        <Skeleton className="control-label" width={'20%'} height={20} borderRadius={0} />
        <Skeleton className="form-control" width={'100%'} height={45} borderRadius={10} />
      </div>
      <div className="form-group">
        <Skeleton className="control-label" width={'20%'} height={20} borderRadius={0} />
        <Skeleton className="form-control" width={'100%'} height={45} borderRadius={10} />
      </div>
      <div className="form-group">
        <Skeleton className="control-label" width={'20%'} height={20} borderRadius={0} />
        <Skeleton className="form-control" width={'100%'} height={45} borderRadius={10} />
      </div>
    </div>
  );
};

export default SkeletonForm;
