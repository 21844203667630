import React from 'react';
import { Link } from 'react-router-dom';
import FooterRibbon from './FooterRibbon';

const Footer = ({ showRibbon = false }: { showRibbon?: boolean }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  return (
    <>
      {showRibbon && <FooterRibbon />}
      <footer className="site-footer">
        <div className="site-footer-container">
          <nav>
            <ul>
              <li>
                <Link to="/about" target="_blank">
                  About
                </Link>
              </li>
              <li>
                <Link to="/faq" target="_blank">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/contact" target="_blank">
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          <div className="site-footer-social">
            <ul className="social-list">
              <li>
                <a
                  className="icon-social icon-twitter"
                  href="//x.com/logolounge"
                  target="_blank"
                  rel="noreferrer"
                >
                  X
                </a>
              </li>
              <li>
                <a
                  className="icon-social icon-facebook"
                  href="//www.facebook.com/LogoLounge/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="icon-social icon-pinterest"
                  href="//www.pinterest.com/logolounge/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pinterest
                </a>
              </li>
              <li>
                <a
                  className="icon-social icon-instagram"
                  href="//instagram.com/logolounge"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className="icon-social icon-linkedin"
                  href="//www.linkedin.com/company/3043287"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
          <nav className="site-footer-details">
            &copy; {currentYear} LogoLounge, LLC. All Rights Reserved.{' '}
            <Link to="/terms" title="Terms of Use and Privacy Policy">
              Terms and Privacy
            </Link>
          </nav>
        </div>
      </footer>

      <div className="submenu_shade"></div>
    </>
  );
};

export default Footer;
