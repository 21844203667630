import * as yup from 'yup';

export const profileSettings = yup.object().shape({
  avatar_filename: yup.string().nullable().notRequired(),
  avatar_base64: yup.string().nullable().notRequired(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  company: yup.string().required(),
  company_description: yup.string().nullable().notRequired(),
  website: yup.string().url().nullable().notRequired(),
  facebook: yup.string().url().nullable().notRequired(),
  twitter: yup.string().url().nullable().notRequired(),
  instagram: yup.string().url().nullable().notRequired(),
  dribbble: yup.string().url().nullable().notRequired(),
  behance: yup.string().url().nullable().notRequired(),
});
