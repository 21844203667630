import { useContext } from 'react';
import { SpinnerContext } from '../context/Spinner';

const useSpinner = () => {
  const context = useContext(SpinnerContext);

  if (context === undefined) {
    throw new Error('useSpinner must be used within a SpinnerProvider');
  }

  return context;
};

export default useSpinner;
