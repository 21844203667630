import React, { useEffect, useRef, useState } from 'react';
import Skeleton from '../../../atoms/Skeleton';

const ActivitySkeleton = () => {
  const [width, setWidth] = useState<number | undefined>(undefined);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, []);

  return (
    <li>
      <span className="time skeleton">
      </span>
      <div className="profile_image skeleton" ref={divRef}>
        <Skeleton className="square" />
      </div>
      <a className="designer_name skeleton">
      </a>
    </li>
  );
};

export default ActivitySkeleton;
