import React, { useState } from 'react';
import { LogoLoungeContext } from '../context/LogoLounge.context';
import { IAdvancedSearch } from '../types/advancedSearch.types';

interface ILogoLoungeProvider {
  children: React.ReactNode;
}

export const LogoLoungeProvider: React.FC<ILogoLoungeProvider> = ({ children }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValues, setSearchValues] = useState<IAdvancedSearch>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  //logo detail
  const [logoId, setLogoId] = useState(0);
  const [logoIndex, setLogoIndex] = useState(0);
  const [hidePrev, setHidePrev] = useState(false);
  const [hideNext, setHideNext] = useState(false);

  return (
    <LogoLoungeContext.Provider
      value={{
        isSearching,
        setIsSearching,
        search: searchValues?.search,
        class: searchValues?.class,
        type: searchValues?.type,
        client: searchValues?.client,
        country: searchValues?.country,
        industry: searchValues?.industry,
        setSearchValues: setSearchValues,
        isSearchOpen,
        setIsSearchOpen,
        /* logo index -- modal */
        logoId,
        setLogoId,
        logoIndex,
        setLogoIndex,
        hideNext,
        hidePrev,
        setHideNext,
        setHidePrev,
      }}
    >
      {children}
    </LogoLoungeContext.Provider>
  );
};
