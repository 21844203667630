import React, { useEffect } from 'react';
import Container from '../../../atoms/Container';
import useModal from '../../../../hooks/useModal';
import CollectionDetailModal from '../../Modals/CollectionDetailModal';
import Logo from '../../../atoms/Logo/Logo';
import { useSearchParams } from 'react-router-dom';
import Skeleton from '../../../atoms/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SKELETON_ITEMS } from '../../../../utils/dummyData';

const FreshContent = ({
  logos,
  actionCallback,
  count,
  page,
  maxPage,
  setPage,
  isLoading,
}: {
  logos: any;
  actionCallback?: any;
  count: number;
  page: number;
  maxPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
}) => {
  const { closeModal, openModal, isModalOpen, type } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('logo_id') && type) {
      openModal();
    }
  }, [searchParams, type]);

  return (
    <Container>
      <div className="logos_outer">
        <ul>
          <InfiniteScroll
            next={() => setPage(page + 1)}
            hasMore={logos.length < count}
            loader=""
            dataLength={count}
            endMessage={!isLoading &&
              <div className="results_count">{count.toLocaleString()} logos</div>
            }
          >
            {logos &&
              logos.map((logo: any, index: number) => (
                <li key={`logo-${index}`}>
                  <Logo
                    logo={logo}
                    section="fresh"
                    index={index}
                    actionCallback={actionCallback}
                  />
                </li>
              ))}
            {isLoading &&
              Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                <li key={`logo-skeleton-${index}`} className="loading">
                  <Skeleton className="square" />
                </li>
              ))}
          </InfiniteScroll>
        </ul>
      </div>
      {isModalOpen && (
        <CollectionDetailModal
          closeDetailModal={closeModal}
          logos={logos}
          actionCallback={actionCallback}
        />
      )}
    </Container>
  );
};

export default FreshContent;
