import React, { useEffect } from 'react';
import Layout from '../components/molecules/Layout';
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    document.title = 'About - LogoLounge';
  }, []);
  
  return (
    <Layout className="terms has_right_background">
      <main id="content">
        <figure></figure>

        <article>
          <div className="content_outer">
            <h1>About LogoLounge</h1>

            <p className="medium">
              Welcome to the world’s premier online logo&nbsp;showcase.
            </p>
            <p>
              With a member community of more than 20,000 designers from more than 68
              countries around the world, LogoLounge.com is the definitive resource for
              logo designers everywhere. For just $100 per year, members enjoy an ad-free
              environment with a wide range of benefits, including unlimited uploads,
              unparalleled search capabilities, news updates and so much more.
            </p>
            <p>
              Watch logo genius and trends emerge in real time, providing inspiration like
              nowhere else. Search the database of more than 400,000 logos, narrowing by
              specific criteria including industry and theme. Then upload your own work
              and get noticed among your peers of accomplished design firms. Lincoln
              Design Co., Unfold, Hey Studio, Pupila, Pragmatika Design, Rule 29. The goes
              goes on an on.
            </p>
            <p>
              The best part? All logos uploaded are automatically entered into the running
              for the LogoLounge Book Series, the world’s preeminent logo design
              competition. Each edition’s pages include the best-of-the-best in identity
              design, selected by a pedigreed panel of some of the most recognized names
              in the industry. Entries are unlimited and included in the cost of annual
              membership. And the bragging rights are eternal.
            </p>

            <section className="feature">
              <h3>Join today.</h3>
              <p className="medium">
                So what are you waiting for? Let your works be&nbsp;seen.
              </p>
              <Link className="button" to="/join">
                Join LogoLounge
              </Link>
            </section>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default About;
