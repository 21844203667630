import React from 'react';

const Loading = () => {
  return (
    <div className="parent-container">
      <div className="loading-overlay">
        <div className="svg-container" />
      </div>
    </div>
  );
};

export default Loading;
