import axios from '../api';
import { INewCollection } from '../types/collection.types';

export const getCollections = async ({
  designer_id,
  collection_id,
  logo_id,
}: {
  designer_id?: number;
  collection_id?: number;
  logo_id?: number;
}) => {
  try {
    const response = await axios.get('/api/collections', {
      params: { designer_id, collection_id, logo_id },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const createCollection = async (values: INewCollection) => {
  try {
    const response = await axios.post('/api/collections', values);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const editCollection = async (values: INewCollection) => {
  try {
    const response = await axios.put('/api/collections', values);
    return (response.status >= 200 && response.status < 300);
  } catch (error) {
    return false;
  }
};

export const saveToCollection = async (collection_id: any, logo_id: any) => {
  try {
    const response = await axios.post('/api/logos/save', {
      collection_id,
      logo_id,
      state: 1,
    });
    return (response.status >= 200 && response.status < 300);
  } catch (error) {
    return false;
  }
};

export const removeFromCollection = async (collection_id: any, logo_id: any) => {
  try {
    const response = await axios.post('/api/logos/save', {
      collection_id,
      logo_id,
      state: 0,
    });
    return (response.status >= 200 && response.status < 300);
  } catch (error) {
    return false;
  }
};
