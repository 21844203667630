import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ProfileMenu from '../ProfileMenu';
import Search from '../Search';
import Login from '../Submenu/Login';
import ClickAwayListener from '../../atoms/ClickAway/ClickAway';
import useLogoLounge from '../../../hooks/useLogoLounge';
import useAuth from '../../../hooks/useAuth';

const Header = ({ user }: { user: boolean }) => {
  const { logout } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isSearchOpen } = useLogoLounge();
  const loginRef = useRef(null);
  const buttonRef = useRef(null);

  let lastScrollTop = 0;
  let wasUp = false;
  const scrollTargets: { top: number; height: number; id: string }[] = [];

  const handleClick = () => {
    if (buttonRef.current) {
      setIsClicked(!isClicked);
    }
  };

  const handleLogOut = async () => {
    await logout();
    navigate('/');
  };

  const handleScroll = () => {
    const currScrollTop = window.scrollY;
    const footerElement = document.querySelector('footer');
    const footerHeight = footerElement?.clientHeight || 0;
    const isUp =
      currScrollTop < lastScrollTop ||
      currScrollTop <= 80 ||
      currScrollTop >= (document.body.scrollHeight - footerHeight || 0) ||
      currScrollTop >= document.body.scrollHeight - window.innerHeight - 80;

    if (!isUp) {
      document.querySelectorAll('.submenu_toggle, #menu_toggle').forEach(element => {
        if (element instanceof HTMLInputElement) {
          element.checked = false;
        }
      });
    }

    if (isUp !== wasUp) {
      wasUp = isUp;
      document.querySelectorAll('.is_up_toggle').forEach(element => {
        if (element instanceof HTMLInputElement) {
          element.checked = isUp;
        }
      });
    }

    lastScrollTop = currScrollTop;

    const headerH =
      (isUp ? document.querySelector('header')?.clientHeight || 0 : 0) +
      (document.querySelector('.tabs')?.clientHeight || 0);

    let activeId: string | undefined;

    for (let i = 0; i < scrollTargets.length; i++) {
      const target = scrollTargets[i];

      if (
        (currScrollTop >= target.top - headerH - 1 || i === 0) &&
        currScrollTop < target.top + target.height - headerH
      ) {
        activeId = target.id;
      }
    }

    if (activeId !== undefined) {
      const activeSubnav = document.querySelector(`.tab[href="#${activeId}"]`);

      document.querySelectorAll('.tab').forEach(tab => {
        if (tab === activeSubnav) {
          tab.classList.add('active');
        } else {
          tab.classList.remove('active');
        }
      });
    }
  };

  useEffect(() => {
    !isSearchOpen && setIsClicked(false);
  }, [isSearchOpen]);

  /* scroll animation */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <input
        type="checkbox"
        aria-hidden="true"
        className="is_up_toggle"
        checked
        readOnly={true}
      />

      <header className={'site-header'}>
        <Link className="logolounge_logo" to="/" title="Home">
          LogoLounge
        </Link>
      </header>

      <input id="menu_toggle" type="checkbox" checked={isSidebarOpen} readOnly={true} />

      <nav className={'site-navigation'} role="navigation">
        <>
          {' '}
          <div className="shade" onClick={() => setIsSidebarOpen(false)}></div>
          <div className="nav_inner">
            <ul>
              {!user ? (
                <>
                  <li className="homepage">
                    <Link to="/">Home</Link>
                  </li>
                  <li className={clsx(currentPath === '/compete' && 'active')}>
                    <Link
                      to={
                        process.env.REACT_APP_LINK_COMPETE ||
                        'https://www.logolounge.com/compete'
                      }
                      title="Logo Design Competition"
                    >
                      Compete
                    </Link>
                  </li>
                  <li className={clsx(currentPath === '/trends' && 'active')}>
                    <Link
                      to={
                        process.env.REACT_APP_LINK_TRENDS ||
                        'https://www.logolounge.com/trends'
                      }
                      title="Logo Trend Reports"
                    >
                      Trends
                    </Link>
                  </li>
                  <li className={clsx(currentPath === '/news' && 'active')}>
                    <Link
                      to={
                        process.env.REACT_APP_LINK_NEWS ||
                        'https://www.logolounge.com/news'
                      }
                      title="Logo News"
                    >
                      News
                    </Link>
                  </li>
                  <li className={clsx(currentPath === '/books' && 'active')}>
                    {/* <!-- has_submenu --> */}
                    <Link
                      to={
                        process.env.REACT_APP_LINK_BOOKS ||
                        'https://www.logolounge.com/books'
                      }
                      title="LogoLounge Books"
                    >
                      Books
                    </Link>
                  </li>
                  <li className="secondary">
                    <Link className="login_button" to="/login">
                      Login
                    </Link>
                  </li>
                  <li className="secondary">
                    <Link className="join_button" to="/join">
                      Join
                    </Link>
                  </li>
                  <li className="secondary label">
                    <span>More</span>
                  </li>
                  <li className="secondary">
                    <Link to="/faq" title="">
                      FAQ
                    </Link>
                  </li>
                  <li className="secondary">
                    <Link to="/contact" title="">
                      Contact
                    </Link>
                  </li>{' '}
                </>
              ) : (
                <>
                  <li className="homepage">
                    <Link to="/">Home</Link>
                  </li>
                  <li className={clsx(currentPath === '/compete' && 'active')}>
                    <Link
                      to={
                        process.env.REACT_APP_LINK_COMPETE ||
                        'https://www.logolounge.com/compete'
                      }
                      onClick={() => setIsSidebarOpen(false)}
                      title="Logo Design Competition"
                    >
                      Compete
                    </Link>
                  </li>
                  <li className={clsx(currentPath === '/trends' && 'active')}>
                    <Link
                      to={
                        process.env.REACT_APP_LINK_TRENDS ||
                        'https://www.logolounge.com/trends'
                      }
                      onClick={() => setIsSidebarOpen(false)}
                      title="Logo Trend Reports"
                    >
                      Trends
                    </Link>
                  </li>
                  <li className={clsx(currentPath === '/news' && 'active')}>
                    <Link
                      to={
                        process.env.REACT_APP_LINK_NEWS ||
                        'https://www.logolounge.com/news'
                      }
                      onClick={() => setIsSidebarOpen(false)}
                      title="Logo News"
                    >
                      News
                    </Link>
                  </li>
                  <li className={clsx(currentPath === '/books' && 'active')}>
                    <Link
                      to={
                        process.env.REACT_APP_LINK_BOOKS ||
                        'https://www.logolounge.com/books'
                      }
                      onClick={() => setIsSidebarOpen(false)}
                      title="LogoLounge Books"
                    >
                      Books
                    </Link>
                  </li>
                  <li className="secondary label">
                    <span>Account</span>
                  </li>
                  <li
                    className={clsx(
                      'secondary',
                      currentPath === '/designers?tab=logos' && 'active'
                    )}
                  >
                    <Link
                      to="/designers?tab=logos"
                      onClick={() => setIsSidebarOpen(false)}
                    >
                      Profile
                    </Link>
                  </li>
                  <li
                    className={clsx(
                      'secondary',
                      currentPath === '/account' && 'active'
                    )}
                  >
                    <Link
                      to="/account/contact-info"
                      onClick={() => setIsSidebarOpen(false)}
                    >
                      Account Settings
                    </Link>
                  </li>
                  <li
                    className={clsx(
                      'secondary',
                      currentPath === '/contact' && 'active'
                    )}
                  >
                    <Link to="/contact">Help</Link>
                  </li>
                  <li className="secondary">
                    <div className="logout_button" onClick={handleLogOut}>
                      Log Out
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>{' '}
        </>
      </nav>

      <label
        className={clsx('menu_toggle_button')}
        onClick={() => setIsSidebarOpen(oldState => !oldState)}
      >
        <span className="a"></span>
        <span className="b"></span>
        <span className="c"></span>
      </label>

      <nav
        className={clsx('user-navigation', !user && 'login-navigation')}
        role="navigation"
      >
        {!user ? (
          <ul>
            <li>
              <ClickAwayListener ref={loginRef} onClickAway={() => setIsLoginOpen(false)}>
                <input
                  id="header-login-dropdown"
                  className="submenu_toggle"
                  type="checkbox"
                  checked={isLoginOpen}
                  readOnly={true}
                />
                <label
                  className="btn has_outline submenu_toggle_button"
                  htmlFor="header-login-dropdown"
                  onClick={() => setIsLoginOpen(oldState => !oldState)}
                >
                  Login
                </label>
                {isLoginOpen && <Login />}
              </ClickAwayListener>

              <div className="submenu_background"></div>
            </li>
            <li>
              <div>
                <Link className="btn" to="/join" title="Join LogoLounge">
                  Join Today
                </Link>
              </div>
            </li>
          </ul>
        ) : (
          <>
            <ul>
              <li className="explore_button_outer">
                <Link
                  className={clsx(
                    'button explore_button',
                    (currentPath === '/' ||
                      currentPath === '/fresh' ||
                      currentPath === '/following' ||
                      currentPath === '/news') &&
                      'active'
                  )}
                  to="/"
                >
                  <span>Explore</span>
                </Link>
              </li>
              <li className="search_button_outer">
                <div
                  className={clsx(
                    'button search_button',
                    currentPath === '/search' && 'active'
                  )}
                  onClick={handleClick}
                  ref={buttonRef}
                >
                  <span>Search</span>
                </div>
              </li>
              <li>
                <Link
                  className={clsx(
                    'button upload_button',
                    currentPath === '/submit' && 'active'
                  )}
                  to="/submit"
                  title="Upload a Logo"
                >
                  <span>Upload</span>
                </Link>
              </li>

              <ProfileMenu currentPath={currentPath} />
            </ul>

            <div className="submenu_background"></div>
          </>
        )}
      </nav>
      {user && (
        <>
          <Search
            currentPath={currentPath}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
          />
          <div className={clsx('search_outer_shade', isSearchOpen && 'open')}></div>
        </>
      )}
    </>
  );
};

export default Header;
