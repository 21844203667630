import { useContext } from 'react';
import { LogoLoungeContext, ILogoLoungeContextType } from '../context/LogoLounge.context'; // Adjust the import path

function useLogoLounge(): ILogoLoungeContextType {
  const context = useContext(LogoLoungeContext);

  if (context === undefined) {
    throw new Error('useLogoLounge must be used within a LogoLoungeProvider');
  }

  return context;
}

export default useLogoLounge;
