import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/molecules/Layout';
import clsx from 'clsx';
import Tabs from '../components/atoms/Tabs';
import TabItem from '../components/atoms/TabItem';
import TabPanel from '../components/atoms/TabPanel';
import ContactInfo from '../components/features/pages/AccountSettings/ContactInfo';
import ProfileSettings from '../components/features/pages/AccountSettings/ProfileSettings';
import PasswordSettings from '../components/features/pages/AccountSettings/PasswordSettings';
import Membership from '../components/features/pages/AccountSettings/Membership';
import { getContactInfo, getProfileInfo } from '../services/designer';
import { IContactInfo, IProfileInfo } from '../types/accountSettings.types';
import useAuth from '../hooks/useAuth';

const Account = () => {
  const sections = ['contact-info', 'edit-profile', 'password', 'membership'];
  const { section } = useParams<string>();
  const [tab, setTab] = React.useState(sections.indexOf(section || 'contact-info'));
  const [profileInfo, setProfileInfo] = useState<IProfileInfo>();
  const [contactInfo, setContactInfo] = useState<IContactInfo>();
  const [loading, setLoading] = useState(false);
  const { avatar } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Account Settings - LogoLounge';
  }, []);

  useEffect(() => {
    handleUserInfo();
  }, [avatar]);

  useEffect(() => {
    setTab(sections.indexOf(section || 'logos'));
  }, [section]);

  const handleTabChange = (newTab: number) => {
    const section = document.querySelector('#root-outer-div');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    navigate('/account/' + sections[newTab]);
  };

  const handleUserInfo = () => {
    setLoading(true);
    Promise.all([
      getProfileInfo(),
      getContactInfo(),
    ]).then(([profileData, contactData]) => {
      setProfileInfo(profileData);
      setContactInfo(contactData);
      setLoading(false);
    });
  };

  return (
    <Layout className={clsx('account')}>
      <main id="content" className="has_tabs tab_count-4">
        <div className="content_outer">
          <h1>Account</h1>
        </div>
        <Tabs value={tab} onChange={handleTabChange}>
          <TabItem label={'Contact Info'} />
          <TabItem label={'Profile'} />
          <TabItem label="Password" />
          <TabItem label="Membership" />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <ContactInfo contactInfo={contactInfo as IContactInfo} isLoading={loading} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ProfileSettings profileInfo={profileInfo} isLoading={loading} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <PasswordSettings />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Membership />
        </TabPanel>
      </main>
    </Layout>
  );
};

export default Account;
