import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getLogos } from '../../../services/logo';
import { ILogo } from '../../../types/logos.types';
import { getProfileLogos, updateProfileLogos } from '../../../services/designer';
import useToast from '../../../hooks/useToast';

const ProfileImages = () => {
  const toast = useToast();
  const { handleSubmit, trigger } = useForm();
  const [profileLogos, setProfileLogos] = useState<ILogo[]>([]);
  const [logos, setLogos] = useState<ILogo[]>([]);

  useEffect(() => {
    getProfileLogos().then(data => {
      setProfileLogos(data.slice()); // slice required to trigger re-render
    });
    getLogos({ designerId: 'me' })
      .then(data => {
        setLogos(data.data);
      })
      .catch(error => {
        setLogos([]);
      });
  }, []);

  const addProfileLogo = (logo: ILogo) => {
    if (profileLogos.length < 10) {
      if (
        !profileLogos.find((profileLogo: ILogo) => profileLogo.logo_id === logo.logo_id)
      ) {
        var newProfileLogos = profileLogos.slice(); // slice required to trigger re-render
        newProfileLogos.push(logo);
        setProfileLogos(newProfileLogos);
      } else {
        toast.addToast({
          type: 'error',
          message: 'This logo is already in your profile logos.',
        });
      }
    } else {
      toast.addToast({
        type: 'error',
        message: 'Only 10 profile logos are allowed.',
      });
    }
  };

  const removeProfileLogo = (logo: ILogo) => {
    var newProfileLogos = profileLogos.filter(
      (profileLogo: ILogo) => profileLogo.logo_id !== logo.logo_id
    );
    setProfileLogos(newProfileLogos);
  };

  const onSubmit = () => {
    updateProfileLogos(
      profileLogos.map(logo => {
        return logo.logo_id;
      })
    ).then(response => {
      if (response === 'Updated') {
        toast.addToast({
          type: 'success',
          message: 'Profile logos updated successfully.',
        });
      } else {
        toast.addToast({
          type: 'error',
          message: 'Error updating profile logos.',
        });
      }
    });
  };

  const handleExternalSubmit = async () => {
    const result = await trigger(); // Dispara la validación del formulario
    if (result) {
      handleSubmit(onSubmit)(); // Si es válido, procede al envío
    }
  };

  return (
    <>
      <hr></hr>
      <p>
        Choose 10 logos to appear on the public version of your profile, where anyone can see. Click the logos to add/remove from “Public Logos.”
      </p>
      <div className="sort-bucket">
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="sort-bucket-header"><strong>Profile Logos</strong> (limit 10)</p>
          <div className="sort-bucket-content">
            <div id="sort-bucket-left" className="logo-container">
              {profileLogos &&
                profileLogos.map((logo: any, index: number) => {
                  return (
                    <div
                      key={'profileLogo-' + index}
                      className="logo-item logo-item--small"
                      onClick={() => {
                        removeProfileLogo(logo);
                      }}
                    >
                      <figure className="logo-item-figure">
                        <div className="logo-item-figure-content">
                          <img alt="" src={logo.url} draggable="false" />
                        </div>
                      </figure>
                      <input type="hidden" name="profile_images[]" value={logo.logo_id} />
                    </div>
                  );
                })}
            </div>
          </div>
        </form>
      </div>
      <div className="sort-bucket">
        <p className="sort-bucket-header"><strong>All Logos</strong></p>
        <div className="sort-bucket-content">
          <div id="sort-bucket-right" className="logo-container">
            {logos &&
              logos.map((logo: any, index: number) => {
                return (
                  <div
                    key={'logo-' + index}
                    className="logo-item logo-item--small"
                    onClick={() => {
                      addProfileLogo(logo);
                    }}
                  >
                    <figure className="logo-item-figure">
                      <div className="logo-item-figure-content">
                        <img alt="" src={logo.url} draggable="false" />
                      </div>
                    </figure>
                    <input type="hidden" name="profile_images[]" value={logo.logo_id} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="button_outer">
        <button className="renew_button" type="submit" onClick={handleExternalSubmit}>
          Save Profile Images
        </button>
      </div>
    </>
  );
};

export default ProfileImages;
