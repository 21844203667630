import React, { useEffect, useMemo, useState } from 'react';
import Container from '../../../atoms/Container';
import AwardItem from './AwardItem';
import CollectionDetailModal from '../../Modals/CollectionDetailModal';
import useModal  from '../../../../hooks/useModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Skeleton from '../../../atoms/Skeleton';

const AwardContent = ({
  slug,
  awards,
  isLoading,
}: {
  slug: string;
  awards: any[];
  isLoading: boolean;
}) => {
  const { closeModal, openModal, isModalOpen } = useModal();
  const [modalLogos, setModalLogos] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const awardsSorted = useMemo(() => {
    const awardsCopy = [...awards];
    return awardsCopy.sort((a, b) => {
      const masterRegex = /Master Book (\d+)/;
      const bookRegex = /Book (\d+)/;

      const masterMatchA = a.book.match(masterRegex);
      const masterMatchB = b.book.match(masterRegex);
      const bookMatchA = a.book.match(bookRegex);
      const bookMatchB = b.book.match(bookRegex);

      const bookNumA = masterMatchA
        ? parseInt(masterMatchA[1], 10)
        : parseInt(bookMatchA[1], 10);
      const bookNumB = masterMatchB
        ? parseInt(masterMatchB[1], 10)
        : parseInt(bookMatchB[1], 10);

      const bookTypeA = masterMatchA ? 'Master' : 'Regular';
      const bookTypeB = masterMatchB ? 'Master' : 'Regular';

      if (bookTypeA !== bookTypeB) return bookTypeA === 'Regular' ? -1 : 1;

      return bookNumB - bookNumA;
    });
  }, [awards]);

  useEffect(() => {
    if (searchParams.has('logo_id')) {
      const logoId = parseInt(searchParams.get('logo_id') || '-1');
      if (awardsSorted.length > 0) {
        const awardIndex = awardsSorted.findIndex(award =>
          award.logos.find((logo: { logo_id: number }) => logo.logo_id === logoId)
        );
        if (awardIndex > -1) {
          setModalLogos(awardsSorted[awardIndex].logos);
          openModal();
        } else {
          navigate('/search');
        }
      }
    }
  }, [searchParams, awardsSorted, openModal, navigate]);

  const handleAwardItemClick = (item: { logos: any[] }) => {
    setModalLogos(item.logos);
  };

  return (
    <Container>
      {isLoading &&
        Array.from({ length: 3 }).map((_, index) => (
          <div className="collection_group">
            <div className="collection_summary">
              <Skeleton
                key={`logo-skeleton-${index}`}
                className="square"
              />
            </div>
            <div className="logo_collection is_start">
              <ul>
                {Array.from({ length: 5 }).map((_, index) => (
                  <li key={`logo-skeleton-${index}`} className="loading">
                    <Skeleton className="square" />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      {awardsSorted &&
        awardsSorted.map((item, index) => (
          <AwardItem
            slug={slug}
            index={index}
            item={item}
            key={`key-item-${index}`}
            handleAwardItemClick={() => handleAwardItemClick(item)}
          />
        ))}
      {isModalOpen && (
        <CollectionDetailModal closeDetailModal={closeModal} logos={modalLogos} />
      )}
    </Container>
  );
};

export default AwardContent;
