import React from 'react';

const TextBooks = () => {
  return (
    <div className="books_group_outer" id="textbooks">
      <h2>Logo Design Textbooks</h2>

      <div className="books_group">
        <article>
          <figure>
            <a
              href="http://www.amzn.com/1592538282/?tag=logoloucom-20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.logolounge.com/images/ll-books/llb_lc.jpg"
                alt="books"
              />
            </a>
          </figure>
          <div className="text_content">
            <h2>
              <a
                href="http://www.amzn.com/1592538282/?tag=logoloucom-20"
                target="_blank"
                rel="noreferrer"
              >
                Logo Creed
              </a>
            </h2>
            <ul className="text_meta">
              <li>by Bill Gardner</li>
              <li>Published: 2013</li>
            </ul>
            <p>
              After many years of planning, corporate identity designer and LogoLounge
              founder Bill Gardner has created a book that speaks to the magic of design
              and provides a glimpse into the designer's creed. Gardner puts logo
              designers directly in touch with strong mentors who speak candidly about the
              processes that lead to successful designs. This textbook helps readers
              navigate the discovery, development and delivery phases necessary to create
              an effective, lasting identity. You'll also learn how to build brand DNA,
              craft presentations for specific situations and clients, tell the brand
              story, and keep your designs vital and alive even after they leave your
              hands. Learn more at{' '}
              <a href="//logocreed.com" target="_blank" rel="noreferrer">
                logocreed.com
              </a>
              .
            </p>

            <a
              className="button"
              href="http://www.amzn.com/1592538282/?tag=logoloucom-20"
              target="_blank"
              title=""
              rel="noreferrer"
            >
              Buy Now
            </a>
          </div>
        </article>
      </div>
    </div>
  );
};

export default TextBooks;
