import React from 'react';
import Skeleton from '../../../../atoms/Skeleton';

const SkeletonForm = () => {
  return (
    <div id="edit-profile">
      <div className="profile_form">
        <div className="profile_image_outer">
          <div className="upload-item">
            <Skeleton height={120} borderRadius={10} />
          </div>
          <label htmlFor="upload-file-input">
            <Skeleton
              className="control-label"
              width={260}
              height={45}
              borderRadius={10}
            />
            <Skeleton
              className="information"
              width={'50%'}
              height={20}
              borderRadius={0}
            />
          </label>
        </div>
        <div className="form-group-wrap--name">
          <div className="form-group">
            <Skeleton
              className="control-label"
              width={'35%'}
              height={20}
              borderRadius={0}
            />
            <Skeleton
              className="form-control"
              width={'100%'}
              height={45}
              borderRadius={10}
            />
          </div>
          <div className="form-group">
            <Skeleton
              className="control-label"
              width={'35%'}
              height={20}
              borderRadius={0}
            />
            <Skeleton
              className="form-control"
              width={'100%'}
              height={45}
              borderRadius={10}
            />
          </div>
        </div>
        <div className="form-group">
          <Skeleton
            className="control-label"
            width={'20%'}
            height={20}
            borderRadius={0}
          />
          <Skeleton
            className="form-control"
            width={'100%'}
            height={45}
            borderRadius={10}
          />
        </div>
        <div className="form-group">
          <Skeleton
            className="control-label"
            width={'20%'}
            height={20}
            borderRadius={0}
          />
          <Skeleton
            className="form-control"
            width={'100%'}
            height={45}
            borderRadius={10}
          />
        </div>
        <div className="form-group">
          <Skeleton
            className="control-label"
            width={'20%'}
            height={20}
            borderRadius={0}
          />
          <Skeleton
            className="form-control"
            width={'100%'}
            height={45}
            borderRadius={10}
          />
        </div>
      </div>
    </div>
  );
};

export default SkeletonForm;
