import { createContext } from 'react';

interface IModalContext {
  isModalOpen: boolean;
  openModal: (type?: 'detail' | 'collection') => void;
  closeModal: () => void;
  setType: React.Dispatch<React.SetStateAction<'detail' | 'collection' | undefined>>;
  type: 'detail' | 'collection' | undefined;
}

export const ModalContext = createContext<IModalContext | undefined>(undefined);
