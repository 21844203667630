import React, { useEffect, useState } from 'react';
import Container from '../../../atoms/Container';
import Logo from '../../../atoms/Logo/Logo';
import useModal from '../../../../hooks/useModal';
import CollectionDetailModal from '../../Modals/CollectionDetailModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SKELETON_ITEMS } from '../../../../utils/dummyData';
import Skeleton from '../../../atoms/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ILogo } from '../../../../types/logos.types';

const LogoContent = ({
  logos,
  actionCallback,
  count,
  page,
  setPage,
  isLoading,
  isAuthenticated,
}: {
  logos: any;
  actionCallback: any;
  count: number;
  page: number;
  maxPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  isAuthenticated: boolean;
}) => {
  const { closeModal, openModal, isModalOpen } = useModal();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [logoId, setLogoId] = useState<number | null>(null);

  const findLogoById = (id: number): ILogo | undefined =>
    logos?.find((logo: ILogo) => logo.logo_id === id);

  useEffect(() => {
    if (searchParams.has('logo_id')) {
      setLogoId(parseInt(searchParams.get('logo_id')!, 10));
      openModal();
    }
    // eslint-disable-next-line
  }, [searchParams, logos]);

  //logo does not exist in public logos
  useEffect(() => {
    if (logoId && logos !== undefined) {
      const logo = findLogoById(logoId);
      if (!isAuthenticated && logo === undefined) {
        console.error('Logo not found, redirecting to login.');
        navigate('/login');
      }
    }
  }, [logoId, logos, isAuthenticated, navigate]);

  return (
    <Container>
      <div className="logos_outer">
        <ul>
          <InfiniteScroll
            next={() => setPage(page + 1)}
            hasMore={logos && logos.length < count}
            loader={Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
              <li key={`logo-skeleton-${index}`} className="loading">
                <Skeleton className="square" />
              </li>
            ))}
            dataLength={count}
            endMessage={
              !isLoading && isAuthenticated &&
                <div className="results_count">{count.toLocaleString()} logos</div>
            }
          >
            {logos &&
              logos.map((logo: any, index: number) => {
                return (
                  <li key={index}>
                    <Logo
                      logo={logo}
                      section="profile"
                      index={index}
                      actionCallback={actionCallback}
                      bookAward={logo.book_id ? `book-${logo.book_id}` : ''}
                      masterBookAward={
                        logo.master_book_id ? `master-book-${logo.master_book_id}` : ''
                      }
                    />
                  </li>
                );
              })}
            {isLoading &&
              Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                <li key={`logo-skeleton-${index}`} className="loading">
                  <Skeleton className="square" />
                </li>
              ))}
          </InfiniteScroll>
        </ul>
      </div>
      {isModalOpen && (
        <CollectionDetailModal
          closeDetailModal={closeModal}
          logos={logos}
          actionCallback={actionCallback}
        />
      )}
    </Container>
  );
};

export default LogoContent;
