import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Layout from '../components/molecules/Layout';
import { useParams } from 'react-router';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AboutContent from '../components/features/pages/AboutContent';
import AwardContent from '../components/features/pages/AwardContent';
import CollectionContent from '../components/features/pages/CollectionContent';
import LikeContent from '../components/features/pages/LikeContent';
import LogoContent from '../components/features/pages/LogoContent';
import Tabs from '../components/atoms/Tabs';
import TabItem from '../components/atoms/TabItem';
import TabPanel from '../components/atoms/TabPanel';
import { getLogosBySlug } from '../services/logo';
import {
  getProfileInfoBySlug,
  getDesignerAwardsBySlug,
  followDesigner,
} from '../services/designer';
import { ILogo } from '../types/logos.types';
import { IProfileInfo } from '../types/accountSettings.types';
import avatarPlaceholder from '../assets/images/layout/logo_lounge_skull.svg';
import { formatUrl, fullUrl } from '../utils/functions';
import FollowersContent from '../components/features/pages/FollowersContent';
import useSpinner from '../hooks/useSpinner';
import usePrevious from '../hooks/usePrevious';
import useToast from '../hooks/useToast';
import Skeleton from '../components/atoms/Skeleton';
import useAuth from '../hooks/useAuth';

const page_size = 20;
const sections = ['logos', 'awards', 'collections', 'likes', 'about', 'followers'];

const Profile = () => {
  const { isAuthenticated, slug: mySlug } = useAuth();
  const { slug } = useParams<string>();
  const activeSlug = useMemo(() => slug || mySlug, [slug, mySlug]);
  const prevSlug = usePrevious(activeSlug);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const spinner = useSpinner();
  const toast = useToast();

  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingAwards, setIsLoadingAwards] = useState(false);
  const [isLoadingLogos, setIsLoadingLogos] = useState(true);

  const [tab, setTab] = useState(sections.indexOf(searchParams.get('tab') || 'logos'));
  const [logoPage, setLogoPage] = useState(1);

  const [profileInfo, setProfileInfo] = useState<IProfileInfo>();
  const [awards, setAwards] = useState<{ book: string; logos: any }[]>([]);
  const [awardsCount, setAwardsCount] = useState(0);
  const [logos, setLogos] = useState<ILogo[]>([]);
  const [logoCount, setLogoCount] = useState(0);
  const [isFollowed, setIsFollowed] = useState(0);

  const isMyProfile = useMemo(() => {
    return activeSlug === mySlug;
  }, [activeSlug, mySlug]);

  const loadProfileInfo = useCallback(() => {
    if (activeSlug) {
      setIsLoadingProfile(true);

      getProfileInfoBySlug(activeSlug).then(data => {
        if (data) {
          document.title = `${data.company || 'Logo'} - LogoLounge`;
          setProfileInfo(data);
          setIsFollowed(data.is_followed);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading profile information.',
          });
        }
        setIsLoadingProfile(false);
      });
    }
  }, [activeSlug]);

  const loadLogos = useCallback(() => {
    setIsLoadingLogos(true);

    getLogosBySlug({ slug: activeSlug, page: logoPage, page_size })
      .then(data => {
        if (data) {
          setLogos(prevLogos => [...prevLogos, ...data.data]);
          setLogoCount(data.count);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading user logos.',
          });
        }
        setIsLoadingLogos(false);
      })
      .catch(error => {
        setLogos([]);
        setIsLoadingLogos(false);
        console.error('Error during refreshLogos fetch:', error);
      });
  }, [activeSlug, logoPage]);

  const loadAwards = useCallback(() => {
    setIsLoadingAwards(true);

    getDesignerAwardsBySlug(activeSlug!)
      .then(data => {
        if (data) {
          var newAwards: any[] = [];
          var count = 0;
          Object.keys(data).forEach(key => {
            newAwards = [...newAwards, { book: key, logos: data[key] }];
            count += data[key].length;
          });
          setAwards(newAwards);
          setAwardsCount(count);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading designer awards.',
          });
        }
        setIsLoadingAwards(false);
      })
      .catch(error => {
        setIsLoadingAwards(false);
      });
  }, [activeSlug]);

  useEffect(() => {
    if (isAuthenticated) {
      loadLogos();
    }
  }, [isAuthenticated, loadLogos]);

  useEffect(() => {
    setTab(sections.indexOf(searchParams.get('tab') || 'logos'));
  }, [searchParams]);

  useEffect(() => {
    if (prevSlug !== activeSlug) {
      setLogos([]);
      setLogoPage(1);
      
      loadProfileInfo();
      if (isAuthenticated) {
        loadAwards();
      } else {
        setIsLoadingLogos(false);
        setIsLoadingAwards(false);
      }
    }
  }, [activeSlug, prevSlug, isAuthenticated]);

  const handleTabChange = (newTab: number) => {
    const div = document.querySelector('#root-outer-div');
    div?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    navigate(`/designers/${activeSlug}?tab=${sections[newTab]}`);
  };

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_liked: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    } else if (action === 'save') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_collected: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    }
  };

  const handleFollow = useCallback(
    (designer_id: number, state: number) => {
      spinner.open();
      followDesigner(designer_id.toString(), state)
        .then(response => {
          if (response) {
            setIsFollowed(state);
            spinner.close();
          } else {
            spinner.close();
            toast.addToast({
              type: 'error',
              message: 'Error following designer.',
            });
          }
        })
        .catch(error => {
          spinner.close();
          toast.addToast({
            type: 'error',
            message: 'Error following designer.',
          });
        });
    },
    [spinner, toast]
  );

  return (
    <Layout className={clsx('profile')} showRibbon={true}>
      <main id="content" className="has_tabs tab_count-5">
        <div className="page_header_outer">
          <header className="page_header">
            <figure className={`profile_image ${!isLoadingProfile && profileInfo!.award_count > 0 && 'is_award_winner'}`}>
              {isLoadingProfile ? (
                <Skeleton
                  className="skeleton_profile_image"
                  borderRadius={10}
                  height={80}
                />
              ) : (
                <img src={profileInfo?.avatar_url || avatarPlaceholder} alt="" />
              )}
            </figure>
            <div>
              {isLoadingProfile ? (
                <>
                  <h1 className="name skeleton"></h1>
                  <div className="info skeleton"></div>
                </>
              ) : (
                <>
                  <h1 className="name">{profileInfo?.company}</h1>
                  <div className="info">
                    {profileInfo?.url && (
                      <>
                        <a href={fullUrl(profileInfo.url)} target="_blank" rel="noreferrer">
                          {formatUrl(profileInfo.url)}
                        </a>{' '}
                      </>
                    )}
                    <span>
                      {profileInfo?.url &&
                        (profileInfo?.city || profileInfo?.state || profileInfo?.country) &&
                        ' · '}
                      {[profileInfo?.city, profileInfo?.state, profileInfo?.country]
                        .filter(Boolean)
                        .join(', ')}
                    </span>
                  </div>
                </>
              )}
            </div>
            <input type="checkbox" className="follow_toggle" id="follow_toggle" />

            <div className="page_actions">
              {isMyProfile ? (
                <Link className="gray_button" to="/account/edit-profile">
                  Edit Profile
                </Link>
              ) : (
                <>
                  {!isAuthenticated ? (
                    <a className="gray_button" href="/join">
                      Follow
                    </a>
                  ) : (
                    isFollowed ? (
                      <span
                        className="gray_button"
                        onClick={() => {
                          handleFollow(profileInfo?.designer_id!, 0);
                        }}
                      >
                        Unfollow
                      </span>
                    ) : (
                      <span
                        className="gray_button"
                        onClick={() => {
                          handleFollow(profileInfo?.designer_id!, 1);
                        }}
                      >
                        Follow
                      </span>
                    )
                  )}
                </>
              )}
            </div>
          </header>
        </div>
        <Tabs value={tab} onChange={handleTabChange}>
          <TabItem
            className={clsx(!isMyProfile && profileInfo?.logo_count === 0 && 'isHidden')}
            label={
              <>
                Logos{' '}
                <span className="number">
                  {!isLoadingProfile && isAuthenticated ? logoCount : profileInfo?.logo_count}
                </span>
              </>
            }
          />
          <TabItem
            className={clsx(
              (isLoadingProfile ||
                (!isMyProfile && profileInfo?.award_count === 0)) &&
                'isHidden'
            )}
            label={
              isAuthenticated ? (
                <>
                  Awards <span className="number">{profileInfo?.award_count}</span>{' '}
                </>
              ) : (
                <a href="/join">
                  Awards <span className="number">{profileInfo?.award_count}</span>{' '}
                </a>
              )
            }
          />
          <TabItem
            label="Collections"
            className={clsx(
              (!isAuthenticated ||
                isLoadingProfile ||
                (!isMyProfile && profileInfo?.collection_count === 0)) &&
                'isHidden'
            )}
          />
          <TabItem
            label="Likes"
            className={clsx((!isMyProfile || !isAuthenticated) && 'isHidden')}
          />
          <TabItem label="About" />
          <TabItem label="Followers" className="isHidden" />
        </Tabs>
        <TabPanel value={tab} index={0}>
          {isAuthenticated ? (
            <LogoContent
              logos={logos}
              actionCallback={actionCallback}
              count={logoCount}
              page={logoPage}
              maxPage={Math.ceil(logoCount / page_size)}
              setPage={setLogoPage}
              isLoading={isLoadingLogos || isLoadingProfile || isLoadingAwards}
              isAuthenticated={isAuthenticated}
            />
          ) : (
            <LogoContent
              logos={profileInfo?.profile_logos}
              actionCallback={actionCallback}
              count={profileInfo?.profile_logos.length || 0}
              page={1}
              maxPage={1}
              setPage={setLogoPage}
              isLoading={isLoadingLogos || isLoadingProfile}
              isAuthenticated={isAuthenticated}
            />
          )}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {isAuthenticated && activeSlug && (
            <AwardContent slug={activeSlug} awards={awards} isLoading={isLoadingAwards} />
          )}
        </TabPanel>
        <TabPanel value={tab} index={2}>
          {isAuthenticated && profileInfo?.designer_id && (
            <CollectionContent designerId={profileInfo?.designer_id} />
          )}
        </TabPanel>
        <TabPanel value={tab} index={3}>
          {isAuthenticated && activeSlug && profileInfo?.designer_id && (
            <LikeContent slug={activeSlug} designerId={profileInfo?.designer_id} />
          )}
        </TabPanel>
        <TabPanel value={tab} index={4}>
          {profileInfo && (
            <AboutContent
              slug={slug || ''}
              company_description={profileInfo?.company_description || ''}
              url={profileInfo?.url || ''}
              twitter={profileInfo?.twitter || ''}
              facebook={profileInfo?.facebook || ''}
              dribbble={profileInfo?.dribbble || ''}
              instagram={profileInfo?.instagram || ''}
              behance={profileInfo?.behance || ''}
              follower_count={profileInfo?.follower_count || 0}
              following_count={profileInfo?.following_count || 0}
              member_since={profileInfo?.member_since || ''}
              isLoading={isLoadingProfile}
            />
          )}
        </TabPanel>
        <TabPanel value={tab} index={5}>
          {profileInfo && <FollowersContent designerId={profileInfo?.designer_id!} />}
        </TabPanel>
      </main>
    </Layout>
  );
};

export default Profile;
