import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import clsx from 'clsx';
import AllBooks from '../components/features/pages/Books/AllBooks/AllBooks';
import TextBooks from '../components/features/pages/Books/TextBooks/TextBooks';
import MasterLibrary from '../components/features/pages/Books/MasterLibrary/MasterLibrary';
import FooterRibbon from '../components/molecules/Footer/FooterRibbon';

const Books = () => {
  useEffect(() => {
    document.title = 'Book Series - LogoLounge';
  }, []);

  const [currentSection, setCurrentSection] = useState('books');
  const [scrollEnabled, setScrollEnabled] = useState(true);

  const handleTabClick = (sectionId: string) => {
    setScrollEnabled(false);
    setCurrentSection(sectionId);
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        setScrollEnabled(true);
      }, 1000);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollEnabled) {
        // Verifica si el scroll está habilitado
        const scrollPosition = window.scrollY;
        const bookSection = document.getElementById('books');
        const textbooksSection = document.getElementById('textbooks');
        const masterLibrarySection = document.getElementById('master-library');

        if (bookSection && textbooksSection && masterLibrarySection) {
          const bookSectionOffsetTop = bookSection.offsetTop;
          const textbooksSectionOffsetTop = textbooksSection.offsetTop;
          const masterLibrarySectionOffsetTop = masterLibrarySection.offsetTop;

          if (
            scrollPosition >= bookSectionOffsetTop &&
            scrollPosition < textbooksSectionOffsetTop
          ) {
            setCurrentSection('books');
          } else if (
            scrollPosition >= textbooksSectionOffsetTop &&
            scrollPosition < masterLibrarySectionOffsetTop
          ) {
            setCurrentSection('textbooks');
          } else if (scrollPosition >= masterLibrarySectionOffsetTop) {
            setCurrentSection('master-library');
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollEnabled]);

  return (
    <Layout className={clsx('books')}>
      <main id="content" className="has_tabs">
        <section className="banner book_10">
          <div className="banner_inner">
            <p className="headline">It’s finally here.</p>
            <p>
              The bestselling LogoLounge book series features the latest and greatest in
              identity design.
            </p>
            <div className="buttons">
              {/* TODO: update link */}
              <a className="button" href="/">
                Get Yours
              </a>
            </div>
            {/* TODO:  */}
            <a href="?">
              <div className="book_image"></div>
            </a>
          </div>
        </section>
        <div className="tabs_outer">
          <div className="tabs">
            <div className="tabs_inner">
              <div
                className={clsx('tab', currentSection === 'books' && 'active')}
                onClick={() => handleTabClick('books')}
              >
                <h1>All Books</h1>
              </div>
              <div
                className={clsx('tab', currentSection === 'textbooks' && 'active')}
                onClick={() => handleTabClick('textbooks')}
              >
                Textbooks
              </div>
              <div
                className={clsx('tab', currentSection === 'master-library' && 'active')}
                onClick={() => handleTabClick('master-library')}
              >
                Master Library
              </div>
            </div>
          </div>
          <div className="tabs_nav_left"></div>
          <div className="tabs_nav_right"></div>
        </div>
        <AllBooks />
        <TextBooks />
        <MasterLibrary />
        <FooterRibbon />
      </main>
    </Layout>
  );
};

export default Books;
