import React, { useState, useEffect } from 'react';
import Layout from '../components/molecules/Layout';
import Container from '../components/atoms/Container';
import CollectionDetailModal from '../components/features/Modals/CollectionDetailModal';
import useLogoLounge from '../hooks/useLogoLounge';
import useModal from '../hooks/useModal';
import { ILogo } from '../types/logos.types';
import { searchLogos } from '../services/logo';
import { useSearchParams } from 'react-router-dom';
import Logo from '../components/atoms/Logo/Logo';
import SearchChips from '../components/molecules/SearchChips';
import Skeleton from '../components/atoms/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import useToast from '../hooks/useToast';
import { SKELETON_ITEMS } from '../utils/dummyData';

const Search = () => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setIsSearchOpen } = useLogoLounge();
  const { closeModal, openModal, isModalOpen } = useModal();

  const page_size = 30;
  const [count, setCount] = useState(0);
  const [logos, setLogos] = useState<ILogo[][]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') || '1', 10));
  const [hasMore, setHasMore] = useState(true);
  const [sort, setSort] = useState(searchParams.get('sort') || 'recent');
  const [isNavigatingInModal, setIsNavigatingInModal] = useState(false);

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like') {
      const newLogos = logos.map((pageLogos: ILogo[]) => {
        return pageLogos.map((logo: ILogo) => {
          if (logo.logo_id === logoId) {
            return {
              ...logo,
              is_liked: params.value,
            };
          } else {
            return logo;
          }
        });
      });
      setLogos(newLogos);
    } else if (action === 'save') {
      const newLogos = logos.map((pageLogos: ILogo[]) => {
        return pageLogos.map((logo: ILogo) => {
          if (logo.logo_id === logoId) {
            return {
              ...logo,
              is_collected: params.value,
            };
          } else {
            return logo;
          }
        });
      });
      setLogos(newLogos);
    }
  };

  const fetchLogos = async (pageNum: number, shouldClear: boolean = false) => {
    if (isNavigatingInModal) return;

    try {
      const data = await searchLogos({
        page: pageNum,
        page_size,
        search_text: searchParams.get('keyword') || '',
        filter_category: searchParams.get('class') || '',
        filter_type: searchParams.get('type') || '',
        search_client: searchParams.get('client') || '',
        search_designer: searchParams.get('designer') || '',
        search_industry: searchParams.get('industry') || '',
        filter_country: searchParams.get('country') || '',
        sort: searchParams.get('sort') || 'recent',
      });

      if (data) {
        if (shouldClear) {
          setLogos([data.data]);
        } else {
          setLogos(prevLogos => [...prevLogos, data.data]);
        }
        setCount(data.count);
        setHasMore(data.data.length === page_size);
      }
    } catch (error) {
      console.error('Error fetching logos:', error);
      if (shouldClear) {
        // Only show error toast on initial load or search
        toast.addToast({
          type: 'error',
          message: 'Error fetching logos.',
        });
      }
    } finally {
      setIsLoading(false);
      setIsFetchingMore(false);
    }
  };

  // Handle search parameter changes (not page or logo_id)
  useEffect(() => {
    const filteredParams = new URLSearchParams(searchParams);
    filteredParams.delete('logo_id');
    filteredParams.delete('page');
    const searchParamsWithoutPageAndLogo = filteredParams.toString();

    setLogos([]);
    if (searchParamsWithoutPageAndLogo !== '') {
      setCurrentPage(1);
      setIsLoading(true);
      fetchLogos(1, true);
    } else if (logos.length === 0) {
      // Initial load
      const initialPage = parseInt(searchParams.get('page') || '1', 10);
      setCurrentPage(initialPage);
      setIsLoading(true);
      fetchLogos(initialPage, true);
    }
  }, [searchParams.toString().replace(/(&?(?:logo_id|page)=[^&]*)/g, '')]);

  const handleSortChange = (newSort: string) => {
    const div = document.querySelector('#root-outer-div');
    div?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('sort', newSort);
    newSearchParams.delete('page');
    newSearchParams.delete('logo_id');
    setSearchParams(newSearchParams);
  };

  const handlePageChange = () => {
    if (isFetchingMore || !hasMore || isLoading) return;
    
    setIsFetchingMore(true);
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    
    // Update URL without triggering a reload
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', nextPage.toString());
    setSearchParams(newSearchParams, { replace: true });
    
    fetchLogos(nextPage, false);
  };

  const handleLogoClick = (logoId: number) => {
    setIsNavigatingInModal(true);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('logo_id', logoId.toString());
    setSearchParams(newSearchParams, { replace: true });
    openModal();
  };

  return (
    <Layout className="search">
      <div className="search_outer">
        <main id="content">
          <Container>
            <div className="results_header">
              <SearchChips onClick={() => setIsSearchOpen(true)} />
              <div className="sort_outer">
                <select
                  id="sort"
                  title="Sort"
                  onChange={event => handleSortChange(event.target.value)}
                  value={searchParams.get('sort') || 'recent'}
                >
                  <option value="recent">Most Recent</option>
                  <option value="popular">Most Popular</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>
            </div>
            {!isLoading && <div className="results_count_top">{count.toLocaleString()} logos</div>}
            <div className="logos_outer">
              <ul>
                <InfiniteScroll
                  next={handlePageChange}
                  hasMore={hasMore}
                  loader={
                    <div className="loading-container">
                      {Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                        <li key={`scroll-loading-${index}`} className="loading">
                          <Skeleton className="square" />
                        </li>
                      ))}
                    </div>
                  }
                  dataLength={logos.flat().length}
                >
                  {logos.map((pageLogos, pageIndex) => (
                    pageLogos.length > 0 && (
                      <React.Fragment key={`page-${pageIndex}`}>
                        <div className="page-separator">
                          <hr />
                          <span className="page-number">
                            Page {pageIndex + 1}
                          </span>
                        </div>
                        {pageLogos.map((logo: any, index: number) => (
                          <li key={`logo-${logo.logo_id}-${index}`}>
                            <Logo
                              logo={logo}
                              section="fresh"
                              index={index}
                              bookAward={logo.book_id ? `book-${logo.book_id}` : ''}
                              masterBookAward={logo.master_book_id ? `master-book-${logo.master_book_id}` : ''}
                              actionCallback={actionCallback}
                            />
                          </li>
                        ))}
                      </React.Fragment>
                    )
                  ))}
                  {isLoading &&
                    Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                      <li key={`skeleton-${index}`} className="loading">
                        <Skeleton className="square" />
                      </li>
                    ))}
                </InfiniteScroll>
              </ul>
            </div>

            {isModalOpen && (
              <CollectionDetailModal
                closeDetailModal={() => {
                  closeModal();
                  setIsNavigatingInModal(false);
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.delete('logo_id');
                  setSearchParams(newSearchParams, { replace: true });
                }}
                logos={logos.flat()}
                setIsNavigatingInModal={setIsNavigatingInModal}
              />
            )}
          </Container>
        </main>
      </div>
    </Layout>
  );
};

export default Search;