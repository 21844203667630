import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import clsx from 'clsx';
import ActivityItem from '../components/features/pages/Activity/ActivityItem';
import { getActivityFeed } from '../services/designer';
import InfiniteScroll from 'react-infinite-scroll-component';
import ActivitySkeleton from '../components/features/pages/Activity/ActivitySkeleton';
import { IActivity, IActivityData } from '../types/activity.types';
import { SKELETON_ITEMS } from '../utils/dummyData';
import { useParams } from 'react-router-dom';

const Activity = () => {
  const { pageParam } = useParams<string>();
  const page_size = 10;
  const [page, setPage] = useState(parseInt(pageParam || '1'));
  const [count, setCount] = useState(0);
  const [activityFeed, setActivityFeed] = useState<IActivity[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getTimeDiff = (dateTime: any) => {
    const now = new Date();
    const then = new Date(dateTime);
    const diff = (now.getTime() - then.getTime()) / 1000; // result is in seconds
    if (diff > 31536000) {
      return Math.floor(diff / 31536000) + 'y';
    } else if (diff > 2628000) {
      return Math.floor(diff / 2628000) + 'mo';
    } else if (diff > 86400) {
      return Math.floor(diff / 86400) + 'd';
    } else if (diff > 3600) {
      return Math.floor(diff / 3600) + 'h';
    } else if (diff > 60) {
      return Math.floor(diff / 60) + 'm';
    }
    return diff + 's';
  };

  const shortenComment = (text: string) => {
    return text.length > 25 ? text.substring(0, 25) + '...' : text;
  };

  const actions: { [key: string]: 'saved' | 'liked' | 'commented' | 'followed' } = {
    save: 'saved',
    like: 'liked',
    comment: 'commented',
    follow: 'followed',
  };

  const handleActivity = () => {
    setIsLoading(true);
    getActivityFeed({ page_size, page })
      .then((data: IActivityData) => {
        setActivityFeed(prevActivityFeed => [...prevActivityFeed, ...data['activity']]);
        setCount(data['count'][0]);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    document.title = 'Activity - LogoLounge';
  }, []);

  useEffect(() => {
    handleActivity();
  }, [page]);

  return (
    <Layout className={clsx('activity')}>
      <main id="content">
        <div className="content_outer">
          <h1>Activity</h1>
          <div>
            <ul className="activity_feed">
              <InfiniteScroll
                next={() => !isLoading && handlePageChange(page + 1)}
                hasMore={activityFeed.length < count}
                loader=""
                dataLength={count}
              >
                {activityFeed.map((item, index) => (
                  <ActivityItem
                    key={index}
                    company={item['company']}
                    logoId={item['logo_id'] || 0}
                    time={getTimeDiff(item['timestamp'])}
                    logoImage={item['logo_url']}
                    profileImage={item['avatar_url']}
                    slug={item['slug']}
                    action={actions[item['type']]}
                    logoName={item['logo_name']}
                    collectionID={item['collection_id']}
                    collectionName={item['collection_name']}
                    comment={item['comment_text'] && shortenComment(item['comment_text'])}
                  />
                ))}
                {isLoading &&
                  Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                    <ActivitySkeleton key={`activity_skeleton_${index}`} />
                  ))}
              </InfiniteScroll>
            </ul>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Activity;
