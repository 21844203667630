import React from 'react';
import Skeleton from '../../../atoms/Skeleton';

const SkeletonEditCollection = () => {
  return (
    <div className="profile_form">
      <div className="form-group">
        <Skeleton className="control-label" width={'35%'} height={20} borderRadius={0} />
        <Skeleton className="form-control" width={'100%'} height={45} borderRadius={10} />
      </div>

      <div className="form-group">
        <Skeleton className="control-label" width={'35%'} height={20} borderRadius={0} />
        <Skeleton className="form-control" width={'100%'} height={45} borderRadius={10} />
      </div>

      <div className="form-group">
        <div className="checkbox_outer">
          <label className="checkbox" htmlFor="logo-checkbox-public" tabIndex={0}>
            <Skeleton className="control-label" width={50} height={20} borderRadius={0} />
          </label>

          <label className="checkbox" htmlFor="logo-checkbox-private" tabIndex={0}>
            <Skeleton className="control-label" width={50} height={20} borderRadius={0} />
          </label>
        </div>
      </div>
    </div>
  );
};

export default SkeletonEditCollection;
