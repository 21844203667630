import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { IAuthStatus } from '../../../../types/accountSettings.types';
import useAuth from '../../../../hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../../../../schemas/login.schema';
import { InferType } from 'yup';
import clsx from 'clsx';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const handleLogin = (values: InferType<typeof loginSchema>) => {
    const { username, password } = values;
    login(username, password).then((response: IAuthStatus) => {
      if (response?.isAuthenticated) {
        navigate('/');
      }
    });
  };

  return (
    <div className="submenu submenu-login">
      <div className="submenu_inner">
        <form onSubmit={handleSubmit(handleLogin)}>
          <input
            type="hidden"
            name="continue"
            value="https://www.logolounge.com/articles/2021logoloungetrendreport"
          />
          <div className="form-group form-group--required">
            <label className="control-label">Username</label>
            <input
              className="form-control"
              type="text"
              placeholder="Username"
              autoCapitalize="none"
              autoCorrect="off"
              spellCheck="false"
              {...register('username')}
            />
            {errors.username?.message && (
              <span className="error_message login_error">
                {errors.username?.message}
              </span>
            )}
          </div>
          <div className="form-group form-group--required">
            <label className="control-label">Password</label>
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              autoCapitalize="none"
              autoCorrect="off"
              spellCheck="false"
              {...register('password')}
            />
            {errors.password?.message && (
              <span className="error_message login_error ">
                {errors.password?.message}
              </span>
            )}
          </div>
          <button
            className={clsx(
              'btn btn-primary login-btn',
              Object.keys(errors).length > 0 && 'errors'
            )}
            type="submit"
          >
            Log in
          </button>
          <Link
            className="forgot_password"
            to="/forgot-password"
            title="Request your password"
          >
            Forgot?
          </Link>
          <Link className="become_a_member" to="/join" title="Become a member">
            Not a member?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
