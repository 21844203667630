// Utility function to convert an image URL to a base64 string
export const convertImageToBase64 = async (fileURI: string): Promise<string | null> => {
  try {
    const response = await fetch(fileURI);
    if (!response.ok) throw new Error('Network response was not ok');

    const blob = await response.blob();
    return new Promise<string | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string | null);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting image to base64:', error);
    return null;
  }
};

export const processFileUrl = (
  imageUrl: string
): Promise<{ base64: string; type: string; filename: string }> => {
  // Return a new promise
  return new Promise((resolve, reject) => {
    fetch(imageUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const type = blob.type; // MIME type of the blob (image)
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          // Safely extracting filename from URL
          const parts = imageUrl.split('/'); // Split URL by '/'
          let filename = parts.pop() || ''; // Get the last part, guard against undefined
          filename = filename.split('#')[0]; // Remove fragment identifier
          filename = filename.split('?')[0]; // Remove query parameters
          resolve({
            base64: base64data.substring(base64data.indexOf(',') + 1), // Base64 data
            type: type, // MIME type
            filename: filename, // Filename of the image
          });
        };
        reader.onerror = error => {
          reject(error); // Reject the promise if there's an error reading the blob
        };
      })
      .catch(error => {
        console.error('Error fetching image:', error);
        reject(error); // Reject the promise if there's an error fetching the image
      });
  });
};
