import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AdvancedSearch from './AdvancedSearch';
import clsx from 'clsx';
import useLogoLounge from '../../../hooks/useLogoLounge';
import { useNavigate } from 'react-router-dom';
import ClickAway from '../../atoms/ClickAway';
import { IAdvancedSearch } from '../../../types/advancedSearch.types';
import usePrevious from '../../../hooks/usePrevious';

const Search = ({
  currentPath,
  isClicked,
}: {
  currentPath: string;
  isClicked: boolean;
  setIsClicked?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    setSearchValues,
    search,
    class: classSearch,
    type,
    client,
    designer,
    industry,
    country,
    isSearchOpen,
    setIsSearchOpen,
  } = useLogoLounge();
  const [hasTyped, setHasTyped] = useState(false);
  const searchRef = useRef(null);
  const prevSearch = usePrevious(search);

  const navigate = useNavigate();
  const methods = useForm<IAdvancedSearch>({
    defaultValues: {
      search,
      class: classSearch,
      type,
      client,
      designer,
      industry,
      country,
    },
  });

  const closeSearch = () => {
    setIsSearchOpen(false);
  };

  const onSubmit = (values: IAdvancedSearch) => {
    setSearchValues(values);
    setHasTyped(true);
    closeSearch();
    const searchParams = new URLSearchParams();
    if (values.search) {
      searchParams.append('keyword', values.search);
    }
    if (values.class) {
      searchParams.append('class', values.class);
    }
    if (values.type) {
      searchParams.append('type', values.type);
    }
    if (values.client) {
      searchParams.append('client', values.client);
    }
    if (values.designer) {
      searchParams.append('designer', values.designer);
    }
    if (values.industry) {
      searchParams.append('industry', values.industry);
    }
    if (values.country) {
      searchParams.append('country', values.country);
    }
    navigate('/search?' + searchParams.toString(), { state: { user: true } });
  };

  useEffect(() => {
    setIsSearchOpen(isClicked);
  }, [isClicked]);

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        methods.setFocus('search');
      }, 100);
    }
  }, [isClicked]);

  useEffect(() => {
    if (prevSearch !== search) {
      methods.setValue('search', search);
    }
  }, [search, prevSearch]);

  return (
    <ClickAway ref={searchRef} onClickAway={() => closeSearch()}>
      <div className={clsx('search_outer', isSearchOpen && 'open')}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="logo_search_form">
            <fieldset>
              <input
                className={clsx('logo_search', hasTyped && 'valueless')}
                type="search"
                placeholder={`${
                  currentPath.includes('/search')
                    ? 'Search by keyword...'
                    : 'Search 400,000+ logos'
                }`}
                autoCapitalize="off"
                spellCheck="false"
                autoCorrect="off"
                {...methods.register('search')}
                onFocus={() => setIsSearchOpen(true)}
                defaultValue={search}
              />
              <button className="logo_search_button" type="submit">
                Search
              </button>
              <div className="close_search_button" onClick={() => closeSearch()}>
                Close
              </div>
            </fieldset>
            {isSearchOpen && <AdvancedSearch />}
          </form>
        </FormProvider>
      </div>
    </ClickAway>
  );
};

export default Search;
