import { Dispatch, SetStateAction, createContext } from 'react';
import { IAdvancedSearch } from '../types/advancedSearch.types';

export interface ILogoLoungeContextType extends IAdvancedSearch {
  isSearching: boolean;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
  setSearchValues: React.Dispatch<React.SetStateAction<IAdvancedSearch | undefined>>;
  isSearchOpen: boolean;
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>;
  /* set index logo detail -- modal */
  logoId: number;
  setLogoId: Dispatch<SetStateAction<number>>;
  logoIndex: number;
  setLogoIndex: Dispatch<SetStateAction<number>>;
  hidePrev: boolean;
  setHidePrev: Dispatch<SetStateAction<boolean>>;
  hideNext: boolean;
  setHideNext: Dispatch<SetStateAction<boolean>>;
}

export const LogoLoungeContext = createContext<ILogoLoungeContextType | undefined>(
  undefined
);
