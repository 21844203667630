import * as yup from 'yup';

export const passwordRequirements = `Password must be between 8 and 20 characters long, containing: one uppercase letter, one lowercase letter, one number, and one special character.`;
export const phoneRegExp = /^$|^(\+\d{1,2}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const joinSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup
    .string()
    .required('Password is required')
    .min(8, passwordRequirements)
    .max(20, passwordRequirements)
    .matches(/(?=.*[a-z])/, passwordRequirements)
    .matches(/(?=.*[A-Z])/, passwordRequirements)
    .matches(/(?=.*[0-9])/, passwordRequirements)
    .matches(/(?=.*[!@#$%^&*])/, passwordRequirements),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  company: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    // Temporarily disabling phone input validation until the frontend automatically applies the required formatting
    // .matches(phoneRegExp, 'Phone number is not valid')
    .required('Enter a valid phone'),
  address1: yup.string().required(),
  address2: yup.string().nullable().notRequired(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  country: yup.string().required(),
});
