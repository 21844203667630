import React from 'react';
import { formatUrl, fullUrl, getDate } from '../../../../utils/functions';
import { Link } from 'react-router-dom';
import Skeleton from '../../../atoms/Skeleton';

const AboutContent = ({
  company_description,
  url,
  twitter,
  facebook,
  dribbble,
  instagram,
  behance,
  follower_count,
  following_count,
  member_since,
  slug,
  isLoading,
}: {
  company_description: string;
  url: string;
  twitter: string;
  facebook: string;
  instagram: string;
  dribbble: string;
  behance: string;
  follower_count: number;
  following_count: number;
  member_since: string;
  slug: string;
  isLoading: boolean;
}) => {
  return (
    <section className="about_profile">
      <div className="content_outer wide">
        <div className="about_section left">
          {isLoading && <Skeleton width={'100%'} height={25} />}
          {company_description && (
            <>
              <h6>Bio</h6>
              <p className="medium">{company_description}</p>
            </>
          )}
        </div>
        <div className="about_section right">
          {isLoading && <Skeleton width={'100%'} height={'100%'} />}
          <h6>{url && 'Website'}</h6>
          {url && (
            <>
              <p>
                <a
                  href={url ? fullUrl(url) : ''}
                  target="_blank"
                  title="Website"
                  rel="noreferrer">
                    {formatUrl(url || '')}
                </a>
              </p>
              <br />
            </>
          )}

          {(facebook || twitter || instagram || behance || dribbble) && (
            <>
              <h6>Social</h6>
              <div className="social_icons">
                {facebook && (
                  <a
                    href={facebook}
                    className="facebook"
                    target="_blank"
                    title="Facebook"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                )}
                {twitter && (
                  <a
                    href={twitter}
                    className="twitter"
                    target="_blank"
                    title="X"
                    rel="noreferrer"
                  >
                    X
                  </a>
                )}
                {instagram && (
                  <a
                    href={instagram}
                    className="instagram"
                    target="_blank"
                    title="Instagram"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                )}
                {dribbble && (
                  <a
                    href={dribbble}
                    className="dribbble"
                    target="_blank"
                    title="Dribbble"
                    rel="noreferrer"
                  >
                    Dribbble
                  </a>
                )}
                {behance && (
                  <a
                    href={behance}
                    className="behance"
                    target="_blank"
                    title="Behance"
                    rel="noreferrer"
                  >
                    Behance
                  </a>
                )}
              </div>
            </>
          )}

          {isLoading ? (
            <Skeleton width={'100%'} height={'100%'} />
          ) : (
            <>
              {' '}
              <h6>More</h6>
              <p>
                <Link to={`/designers/${slug}?tab=followers`}>
                  {follower_count} followers
                </Link>{' '}
                &middot;{' '}
                <Link to={`/designers/${slug}?tab=followers`}>
                  {following_count} following
                </Link>
              </p>
              <p className="footnote">Member since {getDate(member_since || '')}</p>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AboutContent;
