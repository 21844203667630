import React from 'react';
import Modal from '../../../../molecules/Modal';
import { deleteLogo } from '../../../../../services/logo';
import useSpinner from '../../../../../hooks/useSpinner';
import useToast from '../../../../../hooks/useToast';
import { useNavigate } from 'react-router-dom';

const DeleteLogo = ({
  isOpenModal,
  onClose,
  logoId,
}: {
  isOpenModal: boolean;
  onClose: () => void;
  logoId: number;
}) => {
  const spinner = useSpinner();
  const toast = useToast();
  const navigate = useNavigate();

  const handleRemoveLogo = async () => {
    spinner.open();
    const result = await deleteLogo(logoId);
    if (result) {
      navigate('/designers?tab=logos');
      spinner.close();
    } else {
      toast.addToast({
        message: 'Error deleting logo',
        type: 'error',
      });
      spinner.close();
    }
  };
  return (
    <Modal
      isOpen={isOpenModal}
      type={'collection'}
      saveMode={false}
      onClose={onClose}
      actions={
        <>
          <button
            form="add-to-collection"
            className="done_button close"
            onClick={handleRemoveLogo}
          >
            Yes
          </button>
          <label className="new_collection_button" onClick={onClose}>
            Cancel
          </label>

          <button
            className="save_new_collection_button"
            type="submit"
            form="new-collection-form"
            tabIndex={0}
          >
            Cancel
          </button>
        </>
      }
    >
      <form className="modal-delete">
        <div className="form-group">
          <p className="prompt">Are you sure you want to permanently delete this logo?</p>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteLogo;
