import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import clsx from 'clsx';
import { ICollection, INewCollection } from '../types/collection.types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { editCollection, getCollections } from '../services/collections';
import { useForm } from 'react-hook-form';
import SkeletonEditCollection from '../components/features/pages/CollectionContent/SkeletonEditCollection';
import useSpinner from '../hooks/useSpinner';
import useToast from '../hooks/useToast';

const CollectionEdit = () => {
  const toast = useToast();
  useEffect(() => {
    document.title = 'Edit Collection - LogoLounge';
  }, []);

  const spinner = useSpinner();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState<ICollection>();

  const { handleSubmit, register, setValue, reset } = useForm<INewCollection>();

  const onSubmit = (values: INewCollection) => {
    const params = {
      ...values,
      collection_id: collection?.light_box_id,
    };

    spinner.open();
    editCollection(params)
      .then(response => {
        spinner.close();
        if(response){
          navigate('/collection/' + collection?.light_box_id);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error saving collection details.',
          });
        }
      })
      .catch(() => spinner.close());
  };

  useEffect(() => {
    setLoading(true);
    if (location.state === null) {
      navigate('/');
      return;
    } else {
      const collectionId = location.state.collectionId;
      getCollections({ collection_id: collectionId })
        .then(data => {
          if(data){
            setCollection(data);
            reset({
              name: data?.name,
              description: data?.description,
              is_public: data?.is_public.toString(),
            });
          } else {
            toast.addToast({
              type: 'error',
              message: 'Error getting collection details.',
            });
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Error getting collection info:', error);
          setLoading(false);
        });
    }
  }, [location.state, navigate, reset]);

  return (
    <Layout className={clsx('collection')}>
      <main id="content">
        <div className="content_outer">
          <div className="page_header_outer">
            <header className="page_header">
              <h1 className="name">Edit Collection</h1>
            </header>
          </div>
          {loading ? (
            <SkeletonEditCollection />
          ) : (
            <form
              className="profile_form"
              onSubmit={handleSubmit(onSubmit)}
              id="edit-collection-form"
            >
              <div className="form-group">
                <label className="control-label">Title</label>
                <input className="form-control" type="text" {...register('name')} />
              </div>

              <div className="form-group">
                <label className="control-label">Description</label>
                <textarea
                  className="form-control"
                  {...register('description')}
                ></textarea>
              </div>

              <div className="form-group">
                <div className="checkbox_outer">
                  <input
                    id="logo-checkbox-public"
                    type="radio"
                    value="1"
                    {...register('is_public')}
                    onChange={() => setValue('is_public', '1')}
                  />
                  <label className="checkbox" htmlFor="logo-checkbox-public" tabIndex={0}>
                    <span className="control-label">Public</span>
                  </label>

                  <input
                    id="logo-checkbox-private"
                    type="radio"
                    value="0"
                    {...register('is_public')}
                    onChange={() => setValue('is_public', '0')}
                  />
                  <label
                    className="checkbox"
                    htmlFor="logo-checkbox-private"
                    tabIndex={0}
                  >
                    <span className="control-label">Private</span>
                  </label>
                </div>
              </div>
              <div className="button_outer">
                <Link
                  className="gray_button"
                  to={'/collection/' + collection?.light_box_id}
                >
                  Cancel
                </Link>
                <button className="save_btn" type="submit">
                  Save Collection
                </button>
              </div>
            </form>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default CollectionEdit;
