import React from 'react';
import clsx from 'clsx';

interface ITabs {
  className?: string;
  value: number;
  onChange: (newValue: number) => void;
  children: React.ReactNode;
}

const Tabs: React.FC<ITabs> = ({ value, onChange, children, className }) => {
  /*  const childrenNumber = React.Children.count(children);
   const nCols = 12 / childrenNumber; */
  const handleTabClick = (index: number) => {
    onChange(index);
  };

  return (
    <div className={clsx('tabs_outer', className)}>
      <div className="tabs">
        <div className="tabs_inner">
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child as React.ReactElement, {
              isActive: index === value,
              onClick: () => handleTabClick(index),
              //nCols,
            })
          )}
        </div>
      </div>
    </div>
  );
};
export default Tabs;
