import React, { useMemo } from 'react';
import Chip from '../../atoms/Chip';
import { useSearchParams } from 'react-router-dom';
import { getCountries } from '../../../utils/functions';

const SearchChips = ({ onClick }: { onClick: () => void }) => {
  const [searchParams] = useSearchParams();

  const findCountryName = (countryValue: string) => {
    const countries = getCountries();
    const countryObj = countries.find(country => country.value === countryValue);
    return countryObj ? countryObj.name : 'Unknown';
  };

  const filters = useMemo(() => {
    const filters = {
      class: searchParams.get('class') || '',
      type: searchParams.get('type') || '',
      client: searchParams.get('client') || '',
      designer: searchParams.get('designer') || '',
      industry: searchParams.get('industry') || '',
      country: searchParams.get('country') || '',
      sort: searchParams.get('sort') || 'recent',
    };
    return filters;
  }, [searchParams]);

  const chips = [
    { condition: filters.class === 'books', value: 'Awarded' },
    { condition: filters.class === 'ranked', value: 'Jury-Ranked' },
    { condition: filters.class === 'collections', value: 'Collected' },
    { condition: filters.type === 'typographic', value: 'Typographic' },
    { condition: filters.type === 'symbols', value: 'Symbols' },
    { condition: filters.type === 'combo', value: 'Combos' },
    { condition: !!filters.client, label: 'Client', value: filters.client },
    { condition: !!filters.designer, label: 'Designer', value: filters.designer },
    { condition: !!filters.industry, label: 'Industry', value: filters.industry },
    {
      condition: !!filters.country,
      label: 'Country',
      value: findCountryName(filters.country),
    },
  ];

  return (
    <div className="search_chips" title="Filters" onClick={onClick}>
      {chips
        .filter(chip => chip.condition)
        .map((chip, index) => (
          <Chip key={index} label={chip.label as string} value={chip.value} />
        ))}
    </div>
  );
};

export default SearchChips;
