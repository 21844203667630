import React, { forwardRef, useCallback, useEffect, ReactNode, RefObject } from 'react';

interface ClickAwayListenerProps {
  children: ReactNode;
  onClickAway: () => void;
}

const ClickAwayListener = forwardRef<HTMLDivElement, ClickAwayListenerProps>(
  (props, ref) => {
    const { children, onClickAway } = props;

    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        const domNode = (ref as RefObject<HTMLDivElement>).current;

        if (!domNode || !domNode.contains(event.target as Node)) {
          onClickAway();
        }
      },
      [onClickAway, ref]
    );

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [handleClickOutside]);

    return <div ref={ref}>{children}</div>;
  }
);

export default ClickAwayListener;
