import React from 'react';
import { ModalProvider } from './Modal.provider';
import { LogoLoungeProvider } from './LogoLounge.provider';
import { ToastProvider } from './Toast.provider';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import SpinnerProvider from './Spinner.provider';
interface ProvidersProps {
  children: React.ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || 'test',
        currency: 'USD',
        intent: 'subscription',
        vault: true,
        disableFunding: 'credit,paylater,venmo',
      }}
    >
      <SpinnerProvider>
        <ToastProvider>
          <ModalProvider>
            <LogoLoungeProvider>{children}</LogoLoungeProvider>
          </ModalProvider>
        </ToastProvider>
      </SpinnerProvider>
    </PayPalScriptProvider>
  );
};

export default Providers;
