import React from 'react';

const LogoCollection = ({ logo_urls_list }: { logo_urls_list: string }) => {
  const logos = logo_urls_list.split(',').slice(0, 4);
  return logos.map((url, index) => (
    <span key={index}>
      <span>
        <img
          className="image"
          src={url}
          alt=""
          onLoad={e => {
            e.currentTarget.parentElement?.classList.add('loaded');
          }}
        />
      </span>
    </span>
  ));
};

export default LogoCollection;
