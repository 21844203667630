import { useEffect, useRef } from 'react';

type ScrollHandler = () => void;

function useLogoCollections(): [
  ScrollHandler,
  ScrollHandler,
  React.RefObject<HTMLUListElement>,
] {
  const scrollContainerRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const checkScroll = (e: Event) => {
      const element = e.target as HTMLUListElement;
      const l = element?.scrollLeft || 0;
      const contentWidth = element?.scrollWidth || 0;
      const maxL = contentWidth - (element?.clientWidth || 0);

      element?.classList.toggle('is_start', l <= 200);
      element?.classList.toggle('is_end', l >= maxL);
    };
    const currentScrollContainerRef = scrollContainerRef.current; // Copia el valor
    currentScrollContainerRef?.addEventListener('scroll', checkScroll);
    return () => {
      currentScrollContainerRef?.removeEventListener('scroll', checkScroll);
    };
  }, []);

  const scrollLeft: ScrollHandler = () => {
    const el = scrollContainerRef.current;
    el?.scrollBy({
      top: 0,
      left: -210,
      behavior: 'smooth',
    });
  };

  const scrollRight: ScrollHandler = () => {
    const el = scrollContainerRef.current;
    el?.scrollBy({
      top: 0,
      left: +210,
      behavior: 'smooth',
    });
  };

  return [scrollLeft, scrollRight, scrollContainerRef];
}

export default useLogoCollections;
