import React from 'react';
import avatarPlaceholder from '../../../../assets/images/layout/logo_lounge_skull.svg';
import { Link } from 'react-router-dom';

export interface IActivityItem {
  time: string;
  company: string;
  logoImage: string;
  profileImage: string;
  slug: string;
  action: 'saved' | 'liked' | 'commented' | 'followed';
  logoName?: string;
  collectionID?: number;
  collectionName?: string;
  comment?: string;
  logoId: number;
}

const ActivityItem: React.FC<IActivityItem> = ({
  time,
  company,
  logoImage,
  profileImage,
  slug,
  action,
  logoName,
  collectionID,
  collectionName,
  comment,
  logoId,
}) => {
  const classes = {
    saved: 'is_save',
    liked: 'is_like',
    commented: 'is_comment',
    followed: 'is_follow',
  };
  return (
    <li>
      <span className="time">{time}</span>
      {action === 'followed' ? (
        <a
          className={`profile_image ${classes[action]}`}
          href={`designers/${slug}?tab=logos`}
        >
          <img src={profileImage || avatarPlaceholder} alt="" />
        </a>
      ) : (
        <a
          className={`profile_image ${classes[action]}`}
          href={`designers/${slug}?tab=logos`}
        >
          <img src={logoImage} alt="" />
        </a>
      )}
      <a
        className="designer_name"
        href={`designers/${slug}?tab=logos`}
      >
        <img src={profileImage || avatarPlaceholder} alt="" /> {company}{' '}
      </a>
      {action === 'saved' && (
        <>
          {action}{' '}
          <Link className="logo_name" to={'/logo/' + logoId}>
            {logoName}
          </Link>{' '}
          to{' '}
          <a className="collection_name" href={'collection/' + collectionID}>
            {collectionName}
          </a>
        </>
      )}
      {action === 'liked' && (
        <>
          {action}{' '}
          <Link className="logo_name" to={'/logo/' + logoId}>
            {logoName}
          </Link>
        </>
      )}
      {action === 'commented' && (
        <>
          {action} on{' '}
          <Link className="logo_name" to={'/logo/' + logoId}>
            {logoName}
          </Link>
          :<br />"{comment}"
        </>
      )}
      {action === 'followed' && <>{action} you</>}
    </li>
  );
};

export default ActivityItem;
