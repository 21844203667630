import React from 'react';

interface ITabPanel {
  className?: string;
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<ITabPanel> = ({
  children,
  value,
  index,
  className,
  ...props
}) => {
  return (
    <div
      className={className}
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
