import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import tempLogo from '../../../assets/images/temp_logo_d.jpg';
import Logo from './Logo';
import LogoModal from '../../features/Modals/LogoModal';
import {
  addComment,
  deleteComment,
  getLogoComments,
  getLogos,
} from '../../../services/logo';
import {
  IComments,
  formatDateTime,
  getTimeDifference,
  orderCommentsByDate,
} from '../../../utils/functions';
import { getRelated } from '../../../services/logo';
import { followDesigner, likeLogo } from '../../../services/designer';
import { ILogo } from '../../../types/logos.types';
import avatarPlaceholder from '../../../assets/images/layout/logo_lounge_skull.svg';
import SkeletonModal from './SkeletonModal';
import useAuth from '../../../hooks/useAuth';
import useToast from '../../../hooks/useToast';
import { IAdvancedSearch } from '../../../types/advancedSearch.types';

const LogoDetail = ({
  logoId,
  logoIndex,
  actionCallback,
  setSearchValues,
}: {
  logoId: number;
  logoIndex: number;
  actionCallback: any;
  setSearchValues?: React.Dispatch<React.SetStateAction<IAdvancedSearch | undefined>>;
}) => {
  const { designerId: myId, isAuthenticated } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRelated, setIsLoadingRelated] = useState(true);
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [logo, setLogo] = useState<ILogo>();
  const [relatedLogos, setRelatedLogos] = useState<ILogo[]>([]);
  const [comments, setComments] = useState<IComments[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [isFollowed, setIsFollowed] = useState(0);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);

  const [isMyLogo, setIsMyLogo] = useState(false);

  const handleLike = useCallback(() => {
    if (!isAuthenticated) {
      return navigate('/login');
    }
    const newState = logo?.is_liked === 1 ? 0 : 1 || 0;
    likeLogo({ logo_id: logoId, state: newState }).then(response => {
      if (response) {
        if (logo) {
          setLogo({
            ...logo,
            is_liked: newState,
          });
        }
        if (actionCallback) {
          actionCallback(logoId, 'like', { value: newState });
        }
      } else {
        toast.addToast({
          type: 'error',
          message: newState ? 'Error liking logo.' : 'Error unliking logo.',
        });
      }
    });
  }, [logo, logoId, actionCallback]);

  const handleSave = useCallback(
    (logoId: number, action: string, params: any) => {
      if (logo) {
        setLogo({
          ...logo,
          is_collected: params.value,
        });
      }
      if (actionCallback) {
        actionCallback(logoId, action, { value: params.value });
      }
    },
    [actionCallback, logo]
  );

  const handleFollow = useCallback((designer_id: number, state: number) => {
    setIsLoadingFollow(true);
    followDesigner(designer_id.toString(), state).then(response => {
      setIsLoadingFollow(false);
      if (response) {
        toast.addToast({
          type: 'success',
          message: state ? 'Designer followed!' : 'Designer unfollowed.',
        });
        setIsFollowed(state);
      } else {
        toast.addToast({
          type: 'error',
          message: state ? 'Error following designer.' : 'Error unfollowing designer.',
        });
      }
    });
  }, []);

  const handleComment = (event: any) => {
    if (!isAuthenticated) return navigate('/login');
    event.preventDefault();
    setIsLoadingComments(true);
    addComment({ logo_id: logoId, comment: event.target.comment_text.value })
      .then(response => {
        if (response) {
          event.target.comment_text.value = '';
          loadComments();
        } else {
          setIsLoadingComments(false);
          toast.addToast({
            type: 'error',
            message: 'Error adding comment.',
          });
        }
      })
      .catch(error => {
        setIsLoadingComments(false);
        toast.addToast({
          type: 'error',
          message: 'Error adding comment.',
        });
      });
  };

  const handleDeleteComment = (comment_id: any) => {
    setIsLoadingComments(true);
    deleteComment({ comment_id: comment_id })
      .then(response => {
        if (response) {
          loadComments();
        } else {
          setIsLoadingComments(false);
          toast.addToast({
            type: 'error',
            message: 'Error deleting comment.',
          });
        }
      })
      .catch(error => {
        setIsLoadingComments(false);
        toast.addToast({
          type: 'error',
          message: 'Error deleting comment.',
        });
      });
  };

  const loadLogoDetails = useCallback(() => {
    setIsLoading(true);
    const section = document.querySelector('#logo-modal');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    if (logoId > 0) {
      getLogos({ logo_id: logoId }).then(logo => {
        if (logo) {
          setLogo(logo);
          if (logo.keywords) {
            setKeywords(
              logo.keywords
                ?.toString()
                .split(',')
                .filter((word: string) => word.trim() !== '')
            );
          }
          setIsFollowed(logo.is_followed);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading logo details.',
          });
        }
        setIsLoading(false);
      });
    }
  }, [logoId]);

  const loadRelated = useCallback(() => {
    setIsLoadingRelated(true);
    if (logoId > 0) {
      getRelated({ logo_id: logoId })
        .then(data => {
          if (data) setRelatedLogos(data);
          setIsLoadingRelated(false);
        })
        .catch(error => {
          console.error(error);
          setIsLoadingComments(false);
        });
    }
  }, [logoId]);

  const loadComments = useCallback(() => {
    if (logoId > 0) {
      setIsLoadingComments(true);
      getLogoComments(logoId)
        .then(data => {
          if (data) {
            setComments(orderCommentsByDate(data));
          } else {
            toast.addToast({
              type: 'error',
              message: 'Error loading logo comments.',
            });
          }
          setIsLoadingComments(false);
        })
        .catch(error => {
          console.error(error);
          setIsLoadingComments(false);
        });
    }
  }, [logoId]);

  useEffect(() => {
    setIsMyLogo(myId === logo?.designer_id);
  }, [myId, logo]);

  useEffect(() => {
    loadLogoDetails();
    loadRelated();
    loadComments();
  }, [loadComments, loadLogoDetails, loadRelated]);

  return (
    <>
      {isLoading || isLoadingRelated || isLoadingComments ? (
        <SkeletonModal />
      ) : (
        <>
          <div id="logo-modal" className="logo_full">
            <img
              className="image"
              src={logo?.url || tempLogo}
              alt=""
              onLoad={e => {
                e.currentTarget.parentElement?.classList.add('loaded');
              }}
              loading="lazy"
              onError={e => {
                e.currentTarget.src = tempLogo;
              }}
            />
            <div className="actions">
              <input
                type="checkbox"
                className="like_button_toggle"
                id={`logo_modal_like-${logoId}`}
                checked={logo?.is_liked === 1 || false}
                onClick={handleLike}
                readOnly={true}
              />
              <input
                type="checkbox"
                className="save_button_toggle"
                id={`logo_modal_save-${logoId}`}
                checked={
                  logo?.is_collected === 1 ||
                  (logo?.collections && logo?.collections.length > 0) ||
                  false
                }
                readOnly={true}
              />
              <label className="like_button" htmlFor={`logo_modal_like-${logoId}`}>
                Like
              </label>
              <label
                className="save_button"
                htmlFor={`logo_modal_save-${logoId}`}
                onClick={() => {
                  !isAuthenticated ? navigate('/login') : setOpenModal(true);
                }}
              >
                Save
              </label>
              {isMyLogo && 
                <a href={`/edit?logoId=${logoId}`}
                  className="edit_button"
                >
                  Edit
                </a>
              }
            </div>
          </div>

          <div className="text_left">
            <div className="logo_caption">
              <Link
                className="profile_image"
                to={`/designers/${logo?.slug}?tab=logos`}
              >
                <img
                  src={logo?.avatar_url || avatarPlaceholder}
                  alt=""
                  onError={e => {
                    e.currentTarget.src = avatarPlaceholder;
                  }}
                />
              </Link>
              <div className="text">
                <div className="title">{logo?.logo}</div>
                <div className="author">
                  <Link
                    to={`/designers/${logo?.slug}?tab=logos`}
                  >
                    {logo?.company}
                  </Link>
                  {!isMyLogo && !isLoadingFollow && (
                    <>
                      {' '}
                      &middot;{' '}
                      {isFollowed ? (
                        <span
                          className="follow_button"
                          onClick={() => {
                            if (!isAuthenticated) return navigate('/login');
                            if (logo && logo.designer_id) {
                              handleFollow(logo.designer_id, 0);
                            }
                          }}
                        >
                          Unfollow
                        </span>
                      ) : (
                        <span
                          className="follow_button"
                          onClick={() => {
                            if (!isAuthenticated) return navigate('/login');
                            if (logo && logo.designer_id) {
                              handleFollow(logo.designer_id, 1);
                            }
                          }}
                        >
                          Follow
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="caption_description">
              {logo?.description &&
                <>
                  <p>{logo?.description || ''}</p>
                </>
              }
              {logo?.book_id ? (
                <Link
                  className="award"
                  to={`/designers/${logo?.slug}?tab=awards`}
                >
                  <div className={clsx(`award_icon book-${logo?.book_id}`)}></div>
                  <span className="text">
                    <span className="label">Award</span>
                    <span className="title">{'LogoLounge Book ' + logo?.book_id}</span>
                  </span>
                </Link>
              ) : null}
              {logo?.master_book_id ? (
                <Link
                  className="award"
                  to={`/designers/${logo?.slug}?tab=awards`}
                >
                  <div
                    className={clsx(`award_icon master-book-${logo?.master_book_id}`)}
                  ></div>
                  <span className="text">
                    <span className="label">Award</span>
                    <span className="title">
                      {'LogoLounge Master Book ' + logo?.master_book_id}
                    </span>
                  </span>
                </Link>
              ) : null}
            </div>
            <input
              type="checkbox"
              id="more_toggle-button"
              className="more_toggle"
            />
            <label className="more_button" htmlFor="more_toggle-button"></label>
            <div className="more">
              <div className="publish_date">
                {logo && logo.upload_date && formatDateTime(logo?.upload_date)}
              </div>
              <ul className="credits">
              {logo?.designer &&
                <li>
                  <div className="heading">Designer</div>
                  <div className="value">{(logo?.designer)}</div>
                </li>
              }
              {logo?.creative_director &&
                <li>
                  <div className="heading">Creative Director</div>
                  <div className="value">{logo?.creative_director}</div>
                </li>
              }
              {logo?.client &&
                <li>
                  <div className="heading">Client</div>
                  <div className="value">{logo?.client}</div>
                </li>
              }
              {logo?.industry &&
                <li>
                  <div className="heading">Industry</div>
                  <div className="value">{logo?.industry}</div>
                </li>
              }
              </ul>
              <ul className="stats">
                <li className="stat">
                  <div className="heading">Views</div>
                  <div className="value">{logo?.view_count}</div>
                </li>
                <li className="stat">
                  <div className="heading">Saves</div>
                  <div className="value">{logo?.collection_ct}</div>
                </li>
                <li className="stat">
                  <div className="heading">Likes</div>
                  <div className="value">{logo?.like_ct}</div>
                </li>
                <li className="stat">
                  <div className="heading">Comments</div>
                  <div className="value">{comments?.length}</div>
                </li>
              </ul>
            </div>
            <div className="comments">
              <div className="logo-details-comments">
                <div className="comment-bucket">
                  <h6>Comments</h6>

                  {comments.map(comment => (
                    <div key={`comment-${comment.logo_comment_id}`}>
                      <article className="comment">
                        <figure className="avatar">
                          <Link to={`/designers/${comment?.slug}`}>
                            <img
                              src={comment.avatar_url || avatarPlaceholder}
                              alt=""
                              onError={e => {
                                e.currentTarget.src = avatarPlaceholder;
                              }}
                            />
                          </Link>
                        </figure>
                        <p>
                          <Link
                            className="user_name"
                            to={`/designers/${comment?.slug}`}
                          >
                            {comment.company}
                          </Link>
                        </p>
                        <time>{getTimeDifference(comment.date_added)}</time>
                        <p>{comment.comment}</p>
                        <div className="comment-actions">
                          {comment.designer_id === myId && (
                            <button
                              className="icon icon-delete"
                              onClick={() => handleDeleteComment(comment.logo_comment_id)}
                              data-action="destroy-comment"
                              title="Remove Comment"
                            >
                              Remove Comment
                            </button>
                          )}
                        </div>
                      </article>
                    </div>
                  ))}
                </div>
                <form data-action="submit-comment" onSubmit={handleComment}>
                  <textarea
                    className="form-control"
                    name="comment_text"
                    rows={2}
                    placeholder="Add a comment..."
                  ></textarea>
                  <button className="">Post Comment</button>
                </form>
              </div>
            </div>
          </div>
          <div className="text_right">
            <div className="related">
              <ul className="logos">
                <li>
                  {relatedLogos[0] ? (
                    <Logo logo={relatedLogos[0]} section="related" index={logoIndex} />
                  ) : (
                    ''
                  )}
                </li>
                <li>
                  {relatedLogos[1] ? (
                    <Logo logo={relatedLogos[1]} section="related" index={logoIndex} />
                  ) : (
                    ''
                  )}
                </li>
                <li>
                  {relatedLogos[2] ? (
                    <Logo logo={relatedLogos[2]} section="related" index={logoIndex} />
                  ) : (
                    ''
                  )}
                </li>
                <li>
                  {relatedLogos[3] ? (
                    <Logo logo={relatedLogos[3]} section="related" index={logoIndex} />
                  ) : (
                    ''
                  )}
                </li>
              </ul>

              <h6>Tags</h6>
              <ul className="tags">
                {keywords.map((word, index) => (
                  <li key={`keyword-${index}`}>
                    <Link
                      to={`/search?keyword=${encodeURIComponent(word)}`}
                      onClick={() => setSearchValues!({ search: word })}
                    >
                      {word}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {openModal && (
            <LogoModal
              isOpen={openModal}
              closeModal={() => setOpenModal(false)}
              logoId={logoId}
              actionCallback={handleSave}
            />
          )}
        </>
      )}
    </>
  );
};

export default LogoDetail;
