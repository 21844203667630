import React, { FC, useState } from 'react';
import Modal from '../../molecules/Modal';
import Collection from '../pages/CollectionContent/Collection';

interface ILogoModal {
  closeModal: () => void;
  logoId: number;
  actionCallback: any;
  isOpen?: boolean;
}

const LogoModal: FC<ILogoModal> = ({ closeModal, logoId, actionCallback, isOpen }) => {
  const [saveMode, setSaveMode] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      overlapping={true}
      type={'collection'}
      saveMode={saveMode}
      onClose={closeModal}
      header={'Save to Collection'}
      actions={
        <>
          <button type="submit" form="add-to-collection" className="done_button close">
            Done
          </button>
          <label
            className="new_collection_button"
            onClick={() => setSaveMode(oldState => !oldState)}
          >
            Create New
          </label>

          <button
            className="save_new_collection_button"
            type="submit"
            form="new-collection-form"
            tabIndex={0}
          >
            Save Collection
          </button>
        </>
      }
    >
      <Collection
        actionCallback={actionCallback}
        closeDetailModal={closeModal}
        logoId={logoId}
      />
    </Modal>
  );
};

export default LogoModal;
