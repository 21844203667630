import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import clsx from 'clsx';
import { PayPalButtons } from '@paypal/react-paypal-js';
import axios from '../api';
import { getDate } from '../utils/functions';
import { IJoin } from '../types/join.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCheckoutInfo } from '../services/designer';
import useAuth from '../hooks/useAuth';
import useToast from '../hooks/useToast';

const Checkout = () => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, checkAuth } = useAuth();

  // Get userData from the previous page's state
  const [userData, setUserData] = useState<IJoin>(location.state?.userData);
  const [giftCardCode, setGiftCardCode] = useState('');
  const [giftCardError, setGiftCardError] = useState('');
  const [isMonthly, setIsMonthly] = useState(false);
  const [monthExpDate, setMonthExpDate] = useState<Date>(new Date());
  const [yearExpDate, setYearExpDate] = useState<Date>(new Date());

  useEffect(() => {
    if (isAuthenticated) {
      getCheckoutInfo().then(userInfo => {
        if (userInfo) {
          setUserData(prevData => ({
            ...prevData,
            ...userInfo,
          }));
          setRenewDates(userInfo.expire_date < new Date() ? userInfo.expire_date : new Date());
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error getting checkout information.',
          });
        }
      });
    } else if (userData) {
      setRenewDates();
    } else {
      navigate('/join');
    }
  }, []);

  const setRenewDates = (expireDate: string | null = null) => {
    const monthAhead = expireDate ? new Date(expireDate) : new Date();
    monthAhead.setMonth(monthAhead.getMonth() + 1);
    setMonthExpDate(monthAhead);
    const yearAhead = expireDate ? new Date(expireDate) : new Date();
    yearAhead.setFullYear(yearAhead.getFullYear() + 1);
    setYearExpDate(yearAhead);
  };

  const setIsMonthlyMembership = (isMonthly: boolean) => {
    setIsMonthly(isMonthly);
  };

  const createSubscription = (data: any, actions: any) => {
    const planId = isMonthly
      ? process.env.REACT_APP_PAYPAL_MONTHLY_PLAN_ID
      : process.env.REACT_APP_PAYPAL_YEARLY_PLAN_ID;
    return actions.subscription
      .create({
        plan_id: planId,
      })
      .then((orderId: string) => {
        return orderId;
      });
  };

  const onApprove = (data: any, actions: any) => {
    return axios
      .post('/api/register/paypal_approved', {
        orderID: data.orderID,
        subscriptionID: data.subscriptionID,
        user: userData, // Pass the userData along with the PayPal details
        isMonthly,
        isNew: !isAuthenticated,
      })
      .then(response => {
        if (response.status === 200) {
          if (isAuthenticated) {
            checkAuth().then(() => {
              navigate('/login');
            });
          } else {
            navigate('/login');
          }
        } else {
          alert('Transaction completed but registration failed. Please contact LogoLounge support.');
        }
      })
      .catch(error => {
        console.error('Error during PayPal order approval:', error);
        alert('An error occurred during the transaction. Please try again.');
      });
  };

  const handleGiftCardSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    axios
      .post('/api/register/giftcard', {
        gift_card_code: giftCardCode,
        user: userData,
        isMonthly,
        isNew: !isAuthenticated,
      })
      .then(response => {
        const responseData = response.data;
        if (responseData.success) {
          window.location.href = '/login';
        } else {
          setGiftCardError(responseData.error);
        }
      })
      .catch(error => {
        console.error(error.message);
        setGiftCardError('An error occurred during the transaction. Please try again.');
      });
  };

  return (
    <Layout className={clsx('checkout')}>
      <main id="content">
        <article>
          <div className="content_outer has_sticky_left">
            <div className="sticky_content account_info">
              <h6>Your Account</h6>
              <ul>
                <li>{userData?.first_name} {userData?.last_name}</li>
                {userData?.first_name + " " + userData?.last_name !== userData?.company &&
                  <li>{userData?.company}</li>
                }
                {userData?.email && <li>{userData?.email}</li>}
                {userData?.phone && <li>{userData?.phone}</li>}
                {userData?.address1 && (
                  <li>
                    {userData?.address1} {userData?.address2}
                    <br />
                    {userData?.city}, {userData?.state}{' '}
                    {userData?.zip}
                    <br />
                    {userData?.country}
                  </li>
                )}
              </ul>
            </div>

            <div className="non_sticky_content">
              <h1>{isMonthly ? 'Renew' : 'Checkout'}</h1>

              <input
                type="radio"
                id="yearly_plan"
                name="subscription"
                onChange={() => setIsMonthlyMembership(false)}
                defaultChecked
              />
              <input
                type="radio"
                id="monthly_plan"
                name="subscription"
                onChange={() => setIsMonthlyMembership(true)}
              />

              <section className="content_yearly_plan hide_radio">
                <label htmlFor="yearly_plan" className="group">
                  <div className="info">
                    Annual Membership
                    <div className="amount">$100.00</div>
                  </div>
                  <div className="expire_date">
                    Automatically Renews {getDate(yearExpDate.toString(), 'long')}
                  </div>
                </label>
              </section>
{/*}
              <section>
                <label htmlFor="monthly_plan" className="group">
                  <div className="info">
                    Monthly Membership
                    <div className="amount">$10.00</div>
                  </div>
                  <div className="expire_date">
                    Automatically Renews {getDate(monthExpDate.toString(), 'long')}
                  </div>
                </label>
              </section>
*/}
              <br/>
              <br/>
              <br/>
              <br/>
              <input type="radio" id="paypal_method" name="payment_method" />
              <input type="radio" id="gift_card_method" name="payment_method" />

              <section>
                <label htmlFor="paypal_method">Checkout with PayPal or Credit Card</label>
                <div className="paypal_buttons">
                  <PayPalButtons
                    className="button_outer"
                    createSubscription={createSubscription}
                    onApprove={onApprove}
                    style={{ layout: 'vertical' }}
                    forceReRender={[isMonthly, userData]}
                  />
                </div>
              </section>

              <section>
                <label htmlFor="gift_card_method">Checkout with Gift Card</label>
                <form
                  className="gift_card_form"
                  onSubmit={handleGiftCardSubmit}
                  method="post"
                  data-action="block-double-submit"
                  data-action-value="Validating giftcard..."
                >
                  <div className="form-group">
                    <label className="control-label">Gift Card Number</label>
                    <input
                      className="form-control"
                      type="text"
                      name="GiftCard"
                      value={giftCardCode}
                      onChange={e => setGiftCardCode(e.target.value)}
                      required={true}
                    />
                  </div>
                  {giftCardError && (
                    <div className="alert alert-danger">{giftCardError}</div>
                  )}

                  <div className="button_outer">
                    <button className="btn btn-primary" style={{ cursor: 'pointer' }}>
                      Process Gift Card
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default Checkout;
