import React, { useEffect } from 'react';
import Layout from '../components/molecules/Layout';
import FooterRibbon from '../components/molecules/Footer/FooterRibbon';
import Accordion from '../components/atoms/Accordion';
import { account, books, faqs } from '../utils/faqs';
import { Link } from 'react-router-dom';

const Faq = () => {
  useEffect(() => {
    document.title = 'FAQ - LogoLounge - Logo Design Inspiration & Competition';
  }, []);

  return (
    <Layout className="faq has_right_background">
      <main id="content">
        <figure></figure>

        <article className="article" itemType="http://schema.org/Article">
          <div className="content_outer">
            <h1>FAQ</h1>

            <h3>Competition</h3>
            <section className="faqs">
              {faqs.map((faq, index) => (
                <Accordion key={index} title={faq.question} id={`accordion_${index}`}>
                  <p>{faq.answer}</p>
                </Accordion>
              ))}
            </section>
            <p>
              More questions? Check out <a href="compete">our Competition page</a> or drop
              us an email at{' '}
              <a
                href="mailto:info@logolounge.com"
                target="_blank"
                rel="nofollow noreferrer"
              >
                info@logolounge.com
              </a>
              .
            </p>

            <h3>Books</h3>
            <section className="faqs">
              {books.map((book, index) => (
                <Accordion key={index} title={book.question} id={`accordion_${index}`}>
                  <p>{book.answer}</p>
                </Accordion>
              ))}
            </section>

            <h3>Account</h3>
            <section className="faqs">
              {account.map((item, index) => (
                <Accordion key={index} title={item.question} id={`accordion_${index}`}>
                  <p>{item.answer}</p>
                </Accordion>
              ))}
            </section>

            <section className="feature mint">
              <h3>Still have questions?</h3>
              <p>Reach out to our Customer Support.</p>
              <Link className="button" to="/contact">
                Contact Us
              </Link>
            </section>
          </div>
        </article>
      </main>
      <FooterRibbon />
    </Layout>
  );
};

export default Faq;
