import React from 'react';
import Skeleton from '../Skeleton';
import clsx from 'clsx';

const SkeletonModal = () => {
  return (
    <>
      <div id="logo-modal" className="logo_full">
        <Skeleton className="image" />
        <div className="actions">
          <div className="skeleton like_button"></div>
          <div className="skeleton save_button"></div>
        </div>
      </div>
      <div className="text_left">
        <div className="logo_caption">
          <Skeleton className="profile_image" />
          <div className="text">
            <div className="title">
              <Skeleton />
            </div>
            <div className="author">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
      <div className="text_right">
        <div className="related">
          <ul className="logos">
            {Array.from({ length: 4 }).map((_, index) => (
              <li key={`logo-skeleton-${index}`} className="loading">
                <Skeleton className="square" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SkeletonModal;
