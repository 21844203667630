import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IFollowers } from '../../../../types/followers';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { getFollowers, getFollowings } from '../../../../services/designer';
import Skeleton from '../../../atoms/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import useToast from '../../../../hooks/useToast';
import avatarPlaceholder from '../../../../assets/images/layout/logo_lounge_skull.svg';

const page_size = 10;

const FollowersContent = ({ designerId }: { designerId: number }) => {
  const toast = useToast();
  const [followers, setFollowers] = useState<IFollowers>();
  const [followings, setFollowings] = useState<IFollowers>();
  const [isLoadingFollowers, setIsLoadingFollowers] = useState(false);
  const [isLoadingFollowings, setIsLoadingFollowings] = useState(false);
  const [isFollowingTab, setIsFollowingTab] = useState(false);
  const [page, setPage] = useState(1);

  const items = useMemo(() => {
    const defaultItems = { count: 0, data: [] };
    if (isFollowingTab) return followings ? followings : defaultItems;
    return followers ? followers : defaultItems;
  }, [isFollowingTab, followers, followings]);

  const maxPage = useMemo(() => {
    return items.count > 0 ? Math.ceil(items.count / page_size) : 1;
  }, [items]);

  const loadFollowers = useCallback(() => {
    setIsLoadingFollowers(true);
    getFollowers(page, page_size, designerId)
      .then(newData => {
        if(newData){
          setFollowers(currentData => {
            if (page === 1) {
              return newData;
            }
            return {
              ...newData,
              data: [...(currentData?.data || []), ...newData.data],
            };
          });
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading followers.',
          });
        }
        setIsLoadingFollowers(false);
      })
      .catch(error => {
        setIsLoadingFollowers(false);
      });
  }, [designerId, page]);

  const loadFollowings = useCallback(() => {
    setIsLoadingFollowings(true);
    getFollowings(page, page_size, designerId)
      .then(data => {
        if(data){
          setFollowings(data);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading following.',
          });
        }
        setIsLoadingFollowings(false);
      })
      .catch(error => {
        setIsLoadingFollowings(false);
        console.error('Error during getFollowings fetch:', error);
      });
  }, [designerId]);

  useEffect(() => {
    loadFollowers();
    loadFollowings();
  }, [loadFollowers, loadFollowings, page]);

  return (
    <section>
      <div className="content_outer">
        <div className="summary_text content">
          <p
            className={clsx(!isFollowingTab && 'selected')}
            onClick={() => setIsFollowingTab(false)}
          >
            {followers?.count} followers
          </p>
          <p>&nbsp;&middot;&nbsp;</p>
          <p
            className={clsx(isFollowingTab && 'selected')}
            onClick={() => setIsFollowingTab(true)}
          >
            {followings?.count} following
          </p>
          <p>&nbsp;&middot;&nbsp;</p>
          <Link to="/following">Latest from following</Link>
        </div>

        <div className="profiles_outer">
          <ul>
            <InfiniteScroll
              next={() => setPage(page + 1)}
              hasMore={items.data.length < items.count}
              endMessage={
                <div className="results_count">
                  {`${items.count} ${isFollowingTab ? 'Following' : 'Followers'}`}{' '}
                </div>
              }
              loader={
                (isLoadingFollowers || isLoadingFollowings) &&
                Array.from({ length: 10 }).map((_, index) => (
                  <li key={`logo-skeleton-${index}`}>
                    <Link to="">
                      <span className="profile_image">
                        <Skeleton width={'100%'} height={'50px'} borderRadius={10} />
                      </span>
                      <span className="text">
                        <span className="title">
                          <Skeleton width={'100%'} height={'22px'} borderRadius={10} />
                        </span>
                        <span className="infos">
                          <Skeleton width={'100%'} height={'18px'} borderRadius={10} />
                        </span>
                      </span>
                    </Link>
                  </li>
                ))
              }
              dataLength={0}
            >
              {items?.data.length > 0 &&
                items?.data.map(
                  (
                    {
                      avatar_url,
                      logo_count,
                      award_count,
                      company,
                      slug,
                    },
                    index
                  ) => (
                    <li key={`follower_${index}`}>
                      <Link to={`/designers/${slug}?tab=logos`}>
                        <span className="profile_image">
                          <img
                            src={avatar_url || avatarPlaceholder}
                            alt=""
                          />
                        </span>
                        <span className="text">
                          <span className="title">
                            {company}
                          </span>
                          <span className="infos">
                            {logo_count} logo{logo_count !== 1 && "s"} &middot; {award_count} awards
                          </span>
                        </span>
                      </Link>
                    </li>
                  )
                )}
            </InfiniteScroll>
          </ul>

          <div className="page_button_outer prev_button_outer">
            {page > 1 && (
              <div onClick={() => setPage(page - 1)} className="button prev_button">
                Prev
              </div>
            )}
          </div>
          <div className="page_button_outer next_button_outer">
            {page < maxPage && (
              <div onClick={() => setPage(page + 1)} className="button next_button">
                Next
              </div>
            )}
          </div>
          <div className="results_count">
            {items.count} logo{items.count !== 1 && "s"} {maxPage > 0 && <>&middot; Page {page} of {maxPage}</>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FollowersContent;
