import axios from 'axios';
import { getCookieValue } from '../utils/cookies';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  function (config) {
    config.headers['X-CSRFToken'] = getCookieValue('csrftoken');
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
