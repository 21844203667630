import React, { useEffect, useState } from 'react';
import { getMembership } from '../../../../../services/designer';
import { IMembership } from '../../../../../types/accountSettings.types';
import { getDate } from '../../../../../utils/functions';
import { Link } from 'react-router-dom';
import Skeleton from '../../../../atoms/Skeleton';

const Membership = () => {
  const [membershipInfo, setMembershipInfo] = useState<IMembership>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMembership()
      .then(data => {
        if(data){
          setMembershipInfo(data);
        }
        setLoading(false);
      });
  }, []);

  return (
    <section>
      <div className="content_outer">
        {loading ? (
          <Skeleton width={'100%'} height={20} />
        ) : (
          <div id="membership">
            {membershipInfo && 
              <p>
                Your membership expires on{' '}
                {getDate(membershipInfo?.member_expire || '', 'long')}.
              </p>
            }

            <div className="button_outer">
              <Link className="renew_button" to="/checkout">
                Renew Membership
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Membership;
