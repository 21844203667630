import { createContext } from 'react';
import { IAuthStatus } from '../types/accountSettings.types';

interface AuthContextType {
  isLoading: boolean;
  isAuthenticated: boolean;
  isActive: boolean;
  memberType: string;
  designerId: number;
  username: string;
  avatar: string;
  slug: string;
  checkAuth: () => Promise<void>;
  login: (username: string, password: string) => Promise<IAuthStatus>;
  logout: () => Promise<void>;
  updatePassword: (old_password: string, new_password: string) => Promise<string>;
  setAvatar: (avatar: string) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);
