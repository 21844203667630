import clsx from 'clsx';
import React from 'react';

type SkeletonProps = React.CSSProperties & {
  className?: string;
};

const Skeleton = (props: SkeletonProps) => {
  const { className, ...rest } = props;

  return (
    <div className="skeleton-wrapper">
      <div style={{ ...rest }} className={clsx('skeleton', className)}></div>
    </div>
  );
};

export default Skeleton;
