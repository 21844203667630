import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../components/molecules/Layout';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { getDesignerAwardsBySlug, getProfileInfoBySlug } from '../services/designer';
import Container from '../components/atoms/Container';
import Loading from '../components/molecules/Loading';
import Logo from '../components/atoms/Logo/Logo';
import CollectionDetailModal from '../components/features/Modals/CollectionDetailModal';
import { ILogo } from '../types/logos.types';
import useModal from '../hooks/useModal';
import useToast from '../hooks/useToast';

const Award = () => {
  const { slug, book } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { closeModal, openModal, isModalOpen } = useModal();
  const toast = useToast();

  const [company, setCompany] = useState<String>('');
  const [logos, setLogos] = useState<ILogo[] | null>(null);

  const bookName = useMemo(() => {
    return book
      ? 'LogoLounge ' +
          (book.includes('master') ? 'Master ' : '') +
          'Book ' +
          book.substring(book.lastIndexOf('-') + 1)
      : '';
  }, [book]);

  const getCompany = useCallback(() => {
    if (slug) {
      getProfileInfoBySlug(slug).then(response => {
        if (response) {
          setCompany(response.company);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading designer.',
          });
        }
      });
    }
  }, [slug, toast]);

  const getLogos = useCallback(() => {
    if (slug && book) {
      getDesignerAwardsBySlug(slug, book).then(response => {
        if (response) {
          setLogos(response);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error loading designer awards.',
          });
        }
      });
    }
  }, [slug, book, toast]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  useEffect(() => {
    document.title = `Awards for ${company || slug} in ${bookName} - LogoLounge - Work`;
  }, [company, slug, bookName]);

  useEffect(() => {
    getLogos();
  }, [slug, book, getLogos]);

  useEffect(() => {
    if (searchParams.has('logo_id')) {
      openModal();
    }
  }, [searchParams, logos, openModal]);

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like' && logos) {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_liked: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    } else if (action === 'save' && logos) {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_collected: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    }
  };

  return (
    <Layout className={clsx('award')} showRibbon={true}>
      <main id="content">
        <Container>
          <div className="award_summary">
            <div
              className={clsx(
                `award_icon ${bookName.includes('Master') ? 'master-' : ''}book-${bookName.substring(bookName.lastIndexOf(' ') + 1)}`
              )}
            ></div>
            <div className="award_caption">
              <h1>
                <span className="award_title">{bookName}</span>
                <span className="heading_credits">
                  {logos && logos.length} Awards for{' '}
                  <Link to={`/designers/${slug}?tab=logos`}>{company || slug}</Link>
                </span>
              </h1>
            </div>
          </div>
          <div className="logos_outer">
            {!logos ? (
              <Loading />
            ) : (
              <ul>
                {logos &&
                  logos.map((logo: any, index: number) => {
                    return (
                      <li key={index}>
                        <Logo
                          logo={logo}
                          section="profile"
                          index={index}
                          bookAward={
                            !bookName.includes('Master')
                              ? `book-${bookName.substring(bookName.lastIndexOf(' ') + 1)}`
                              : ''
                          }
                          masterBookAward={
                            bookName.includes('Master')
                              ? `master-book-${bookName.substring(bookName.lastIndexOf(' ') + 1)}`
                              : ''
                          }
                          actionCallback={actionCallback}
                        />
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
          {isModalOpen && logos && (
            <CollectionDetailModal
              closeDetailModal={closeModal}
              logos={logos}
              actionCallback={actionCallback}
            />
          )}
        </Container>
      </main>
    </Layout>
  );
};

export default Award;
