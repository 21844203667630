import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { AuthProvider } from './providers/AuthProvider';
import ProtectedRoute from './routes/ProtectedRoute';

import Explore from './pages/Explore';
import Search from './pages/Search';
import Profile from './pages/Profile';
import Contact from './pages/Contact';
import Collection from './pages/Collection';
import Activity from './pages/Activity';
import Submit from './pages/Submit';
import Join from './pages/Join';
import Books from './pages/Books';
import Award from './pages/Award';
import Account from './pages/Account';
import Terms from './pages/Terms';
import Checkout from './pages/Checkout';
import CollectionEdit from './pages/CollectionEdit';
import LoginPage from './pages/Login';
import Logo from './pages/Logo';
import About from './pages/About';
import Faq from './pages/Faq';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import Loading from './components/molecules/Loading';

function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  const version = packageInfo.version;

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <Router>
        <AuthProvider>
          <Routes>
            {/* Unprotected Routes */}
            <Route path="/designers/:slug?" element={<Profile />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/join" element={<Join />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/:section?/:pageParam?" element={<Explore />} />
              <Route path="/search" element={<Search />} />
              <Route path="/collection/:collectionId" element={<Collection />} />
              <Route path="/collection-edit" element={<CollectionEdit />} />
              <Route path="/activity/:pageParam?" element={<Activity />} />
              <Route path="/books" element={<Books />} />
              <Route path="/award/:slug/:book" element={<Award />} />
              <Route path="/submit" element={<Submit />} />
              <Route path="/edit" element={<Submit />} />
              <Route path="/account/:section?" element={<Account />} />
              <Route path="/logo/:logoId" element={<Logo />} />
            </Route>
            {/* Default Redirect */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AuthProvider>
      </Router>
    </CacheBuster>
  );
}

export default App;
