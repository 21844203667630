import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Logo from '../../../atoms/Logo/Logo';
import useLogoCollections from '../../../../hooks/useLogoCollection';
import { ILogo } from '../../../../types/logos.types';
import { Link } from 'react-router-dom';

type AwardItemProps = {
  slug: string;
  index: number;
  item: {
    book: string;
    logos: ILogo[];
  };
  handleAwardItemClick: (item: { logos: any[] }) => void;
};

const AwardItem = ({
  slug,
  index,
  item,
  handleAwardItemClick,
}: AwardItemProps) => {
  const [scrollLeft, scrollRight, scrollContainerRef] = useLogoCollections();
  const [logos, setLogos] = useState<ILogo[]>([]);

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_liked: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    } else if (action === 'save') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_collected: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    }
  };

  const handleLogos = () => {
    setLogos(logos);
    handleAwardItemClick({ logos: item.logos });
  };

  useEffect(() => {
    setLogos(item.logos);
  }, [item.logos]);

  return (
    <div className="collection_group">
      <div className="collection_summary">
        <div
          className={clsx(
            `award_icon ${item.book.includes('Master') ? 'master-' : ''}book-${item.book.substring(item.book.lastIndexOf(' ') + 1)}`
          )}
        ></div>
        <div className="award_caption">
          <div className="award_title">{item.book}</div>
          <div className="caption">
            {logos.length} logo{logos.length !== 1 && 's'}
          </div>
        </div>
      </div>
      <ul className="logo_collection is_start" ref={scrollContainerRef}>
        {logos.slice(0, 7).map((logo, j) => (
          <li key={`li-logo_${j}`} onClick={handleLogos}>
            <Logo
              logo={logo}
              section={`award-${index}`}
              index={j}
              actionCallback={actionCallback}
              bookAward={
                !item.book.includes('Master')
                  ? `book-${item.book.substring(item.book.lastIndexOf(' ') + 1)}`
                  : ''
              }
              masterBookAward={
                item.book.includes('Master')
                  ? `master-book-${item.book.substring(item.book.lastIndexOf(' ') + 1)}`
                  : ''
              }
            />
          </li>
        ))}
        {logos.length > 6 && (
          <li className="square_button_outer">
            <Link
              className="square_button"
              to={`/award/${slug}/${item.book.includes('Master') ? 'master-book-' : 'book-' + item.book.substring(item.book.lastIndexOf(' ') + 1)}`}
            >
              <span className="count">{logos.length}</span> logos
            </Link>
          </li>
        )}
      </ul>
      <div className="scroll_button left_button" onClick={scrollLeft} />
      {logos.length > 4 && (
        <div className="scroll_button right_button" onClick={scrollRight} />
      )}
    </div>
  );
};

export default AwardItem;
