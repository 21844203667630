import clsx from 'clsx';
import React from 'react';

const Container = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <section>
      <div className={clsx('content_outer wide', className)}>{children}</div>
    </section>
  );
};

export default Container;
