import * as yup from 'yup';

export const passwordRequirements = `Password must be between 8 and 20 characters long, containing: one uppercase letter, one lowercase letter, one number, and one special character.`;

export const resetPasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .required('Password is required')
    .min(8, passwordRequirements)
    .max(20, passwordRequirements)
    .matches(/(?=.*[a-z])/, passwordRequirements)
    .matches(/(?=.*[A-Z])/, passwordRequirements)
    .matches(/(?=.*[0-9])/, passwordRequirements)
    .matches(/(?=.*[!@#$%^&*])/, passwordRequirements),
  confirm_password: yup
  .string()
  .required('Confirm password is required')
  .oneOf([yup.ref("new_password")], "Passwords do not match")
});
