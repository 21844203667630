import React, { useState, useEffect } from 'react';
import Container from '../../../atoms/Container';
import Logo from '../../../atoms/Logo/Logo';
import useAuth from '../../../../hooks/useAuth';
import useModal from '../../../../hooks/useModal';
import useToast from '../../../../hooks/useToast';
import CollectionDetailModal from '../../Modals/CollectionDetailModal';
import { ILogo } from '../../../../types/logos.types';
import { getLiked } from '../../../../services/designer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SKELETON_ITEMS } from '../../../../utils/dummyData';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from '../../../atoms/Skeleton';

const LikeContent = ({ slug, designerId }: { slug: string; designerId: number }) => {
  const { designerId: myId } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const isOwnProfile = myId === designerId;

  const [logos, setLogos] = useState<ILogo[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const page_size = 30;
  const [isLoading, setIsLoading] = useState(true);

  const { closeModal, openModal, isModalOpen } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('logo_id')) {
      openModal();
    }
  }, [searchParams]);

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like') {
      if (isOwnProfile) {
        const newLogos = logos.filter((logo: ILogo) => logo.logo_id !== logoId);
        setLogos(newLogos);
        closeModal();
      } else {
        const newLogos = logos.map((logo: ILogo) => {
          if (logo.logo_id === logoId) {
            return {
              ...logo,
              is_liked: params.value,
            };
          } else {
            return logo;
          }
        });
        setLogos(newLogos);
      }
    } else if (action === 'save') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_collected: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    }
  };

  const refreshLogos = () => {
    setIsLoading(true);
    getLiked(page, page_size, designerId)
      .then(data => {
        if (data) {
          setLogos(prevLogos => [...prevLogos, ...data.data]);
          setCount(data.count);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error getting liked logos.',
          });
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        toast.addToast({
          type: 'error',
          message: 'Error getting liked logos.',
        });
      });
  };

  useEffect(() => {
    refreshLogos();
    // eslint-disable-next-line
  }, [page]);

  if (!isOwnProfile) navigate(`/designers/${slug}?tab=logos`);

  return (
    <Container>
      <div className="logos_outer">
        <ul>
          <InfiniteScroll
            next={() => setPage(page + 1)}
            hasMore={false}
            loader={Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
              <li key={`logo-skeleton-${index}`} className="loading">
                <Skeleton className="square" />
              </li>
            ))}
            dataLength={count}
            endMessage={<div className="results_count">{count} likes</div>}
          >
            {logos.map((logo: any, index: number) => (
              <li key={`logo-likes_${index}`}>
                <Logo
                  logo={logo}
                  section="likes"
                  index={index}
                  actionCallback={actionCallback}
                />
              </li>
            ))}
            {isLoading &&
              Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                <li key={`logo-skeleton-${index}`} className="loading">
                  <Skeleton className="square" />
                </li>
              ))}
          </InfiniteScroll>
        </ul>
      </div>
      {isModalOpen && (
        <CollectionDetailModal
          closeDetailModal={closeModal}
          logos={logos}
          actionCallback={actionCallback}
        />
      )}
    </Container>
  );
};

export default LikeContent;
