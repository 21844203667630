import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ClickAway from '../../atoms/ClickAway';
import { getShortActivityFeed, getProfileInfo } from '../../../services/designer';
import avatarPlaceholder from '../../../assets/images/layout/logo_lounge_skull.svg';
import { IProfileInfo } from '../../../types/accountSettings.types';
import useAuth from '../../../hooks/useAuth';

const ProfileMenu = ({ currentPath }: { currentPath: string }) => {
  const { avatar } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [activityFeed, setActivityFeed] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [profileInfo, setProfileInfo] = useState<IProfileInfo>();
  
  useEffect(() => {
    const fetchActivityFeed = async () => {
      const data = await getShortActivityFeed();
      if (data) {
        setActivityFeed(data['activity']);
      }
      setIsLoaded(true);
    };

    if (isMenuOpen) {
      fetchActivityFeed();
    }
  }, [isMenuOpen]);

  const getTimeDiff = (dateTime: string | number | Date) => {
    const now = new Date();
    const then = new Date(dateTime);
    const diff = (now.getTime() - then.getTime()) / 1000;

    if (diff < 0) {
      return 'just now';
    }

    if (diff > 31536000) {
      return Math.floor(diff / 31536000) + 'y';
    } else if (diff > 2628000) {
      return Math.floor(diff / 2628000) + 'mo';
    } else if (diff > 86400) {
      return Math.floor(diff / 86400) + 'd';
    } else if (diff > 3600) {
      return Math.floor(diff / 3600) + 'h';
    } else if (diff > 60) {
      return Math.floor(diff / 60) + 'm';
    }
    return Math.floor(diff) + 's';
  };

  const makeLink = (item: any, index: number) => {
    if (item) {
      if (item['type'] === 'like') {
        return (
          <li key={`link-item_${index}`}>
            <span className="time">{getTimeDiff(item['timestamp'])}</span>
            <Link
              className="profile_image is_like"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              <img src={item['logo_url']} alt="" />
            </Link>
            <Link
              className="designer_name"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              {item['company']}
            </Link>{' '}
            liked <Link to={'/logo/' + item.logo_id}>{item['logo_name']}</Link>
          </li>
        );
      } else if (item['type'] === 'save') {
        return (
          <li key={`link-item_${index}`}>
            <span className="time">{getTimeDiff(item['timestamp'])}</span>
            <Link
              className="profile_image is_save"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              <img src={item['logo_url']} alt="" />
            </Link>
            <Link
              className="designer_name"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              {item['company']}
            </Link>{' '}
            saved <Link to={'/logo/' + item.logo_id}>{item['logo_name']}</Link> to{' '}
            <Link className="collection_name" to={'/collection/' + item['collection_id']}>
              {item['collection_name']}
            </Link>
          </li>
        );
      }
      if (item['type'] === 'comment') {
        const text = item['comment_text'];
        return (
          <li key={`link-comment-item_${index}`}>
            <span className="time">{getTimeDiff(item['timestamp'])}</span>
            <Link
              className="profile_image is_comment"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              <img src={item['logo_url']} alt="" />
            </Link>
            <Link
              className="designer_name"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              {item['company']}
            </Link>{' '}
            commented on <Link to={'/logo/' + item.logo_id}>{item['logo_name']}</Link>:
            {' ' + (text.length > 25 ? text.substring(0, 25) + '...' : text)}
          </li>
        );
      }
      if (item['type'] === 'follow') {
        return (
          <li key={`link-follow-item_${index}`}>
            <span className="time">{getTimeDiff(item['timestamp'])}</span>
            <Link
              className="profile_image is_follow"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              <img src={item['avatar_url'] || avatarPlaceholder} alt="" />
            </Link>
            <Link
              className="designer_name"
              to={`/designers/${item['slug']}?tab=logos`}
            >
              {item['company']}
            </Link>{' '}
            followed you
          </li>
        );
      }
    }
    return '';
  };

  useEffect(() => {
    getProfileInfo().then(data => {
      if (data) {
        setProfileInfo(data);
      }
    });
  }, []);

  // This useEffect will cause the component to re-render when avatar changes
  useEffect(() => {
  }, [avatar]);

  return (
    <li className="has_submenu has_activity_menu">
      <ClickAway ref={menuRef} onClickAway={() => setIsMenuOpen(false)}>
        <input
          id="activity_menu_toggle"
          className="submenu_toggle"
          type="checkbox"
          checked={isMenuOpen}
          readOnly={true}
        />
        <label
          className={clsx(
            'button activity_menu_toggle_button',
            currentPath === '/designers' && 'active'
          )}
          htmlFor="activity_menu_toggle"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <img src={avatar || avatarPlaceholder} alt="Account" />
        </label>
        {isMenuOpen && (
          <div className="submenu">
            <div className="submenu_inner">
              <div className="activity_outer">
                <ul>
                  {!isLoaded &&
                    Array.from({ length: 5 }).map((_, index) => (
                      <li key={index}>
                        <a className="profile_image skeleton"></a><a className="designer_name skeleton"></a>
                      </li>
                    ))
                  }
                  {activityFeed &&
                    activityFeed.map((item, index) => makeLink(item, index))}
                </ul>

                <div className="button_outer">
                  <Link className="all_button" to="/activity">
                    <span>Recently</span>
                  </Link>
                </div>
              </div>

              <Link
                className="profile_summary"
                to="/designers?tab=logos"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                state={{ user: true }}
              >
                <span className="profile_image">
                  <img src={avatar || avatarPlaceholder} alt="" />
                </span>
                <span className="text">
                  <span className="title">{profileInfo?.company}</span>
                  <span className="infos">
                    {profileInfo?.logo_count} logo
                    {profileInfo?.logo_count && profileInfo?.logo_count !== 1 && 's'}{' '}
                    &middot; {profileInfo?.award_count} awards
                  </span>
                </span>
              </Link>
            </div>
          </div>
        )}
      </ClickAway>
    </li>
  );
};

export default ProfileMenu;
